import React, { useEffect, useRef } from 'react';
import NvIcon from 'shared/components/nv-icon';
import { css } from '@emotion/react';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import ClickableContainer from 'components/clickable-container';
import t from 'react-translate';
import NvTooltip from 'shared/components/nv-tooltip';
import { useSelector } from 'react-redux';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { useAppDispatch } from 'redux/store';
import { setLeftPanelNovoAILengthAndTone } from 'redux/actions/lecture-pages';
import { getNovoAIProperties } from 'redux/selectors/lecture-page';
import GenerateButton from '../shared/generate-button';
import useAIComponentsGenerator from '../../hooks/use-ai-components-generator';
import AICommonStyles from '../shared/ai-common-styles';
import { config } from '../../../../../../../config/config.json';

const Length = {
  SHORT: 'short',
  MEDIUM: 'medium',
  LONG: 'long',
};

const Tone = {
  DEFAULT: 'formal',
  PROFESSIONAL: 'professional',
  FRIENDLY: 'friendly',
};

const Preferences = () => {
  const preferencesRef = useRef<HTMLDivElement>(null);
  const novoAIProperties = useSelector(getNovoAIProperties);
  const {
    itemType: selectedItemType,
    lengthText: selectedLength,
    tone: selectedTone,
  } = novoAIProperties;
  const dispatch = useAppDispatch();
  const useAIComponentsMethods = useAIComponentsGenerator();
  const {
    createAIComponent,
  } = useAIComponentsMethods;
  const generate = () => {
    createAIComponent({
      aiProps: {
        length: novoAIProperties.lengthText,
        tone: novoAIProperties.tone,
      },
    });
  };
  const setSelectedLengthAndTone = (lengthText, tone) => {
    dispatch(setLeftPanelNovoAILengthAndTone({ lengthText, tone }));
  };
  const setSelectedLength = (lengthText) => {
    dispatch(setLeftPanelNovoAILengthAndTone({ lengthText, tone: selectedTone }));
  };
  const setSelectedTone = (tone) => {
    dispatch(setLeftPanelNovoAILengthAndTone({ lengthText: selectedLength, tone }));
  };
  useEffect(() => {
    setSelectedLengthAndTone(Length.MEDIUM, Tone.DEFAULT);
  }, []);
  const preferencesStyles = css`
    ${AICommonStyles};
    .title {
      padding: ${standardSpacing}px ${standardSpacing}px ${halfSpacing}px ${standardSpacing}px;
    }
    .options {
      justify-content: space-between;
      .option {
        width: 100px;
        height: 70px;
        text-align: center;
        align-content: center;
        .icon {
          padding: ${halfSpacing}px;
        }
      }
    }
  `;
  useEffect(() => {
    if (preferencesRef.current) {
      preferencesRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <React.Fragment>
      <div className='d-flex flex-column my-8' ref={preferencesRef} css={preferencesStyles}>
        <div className='page-title text-center'>
          { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.HEADER() }
        </div>
        {/* Length selector */}
        <div className='length'>
          <div className='title'>
            { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.HEADER() }
          </div>
          <div className='options d-flex align-items-center'>
            <NvTooltip
              text={
                selectedItemType === NovoAIItemType.SUMMARY
                  ? t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.SHORT_SUMMARY_POPOVER()
                  : t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.SHORT_TAKEAWAYS_POPOVER()
              }
              placement='top'
            >
              <ClickableContainer
                onClick={(e) => setSelectedLength(Length.SHORT)}
                data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.preferences.shortLength}
              >
                <div className={`option ${selectedLength === Length.SHORT ? 'selected' : ''}`}>
                  <NvIcon
                    icon='length-short'
                    size='small'
                    className={selectedLength === Length.SHORT ? 'text-ai-color' : ''}
                  />
                  <span className='font-weight-bolder'>
                    { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.SHORT() }
                  </span>
                </div>
              </ClickableContainer>
            </NvTooltip>
            <NvTooltip
              text={
                selectedItemType === NovoAIItemType.SUMMARY
                  ? t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.MEDIUM_SUMMARY_POPOVER()
                  : t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.MEDIUM_TAKEAWAYS_POPOVER()
              }
              placement='top'
            >
              <ClickableContainer
                onClick={(e) => setSelectedLength(Length.MEDIUM)}
                data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.preferences.mediumLength}
              >
                <div className={`option ${selectedLength === Length.MEDIUM ? 'selected' : ''}`}>
                  <NvIcon
                    icon='length-medium'
                    size='small'
                    className={selectedLength === Length.MEDIUM ? 'text-ai-color' : ''}
                  />
                  <span className='font-weight-bolder'>
                    { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.MEDIUM() }
                  </span>
                </div>
              </ClickableContainer>
            </NvTooltip>
            <NvTooltip
              text={
                selectedItemType === NovoAIItemType.SUMMARY
                  ? t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.LONG_SUMMARY_POPOVER()
                  : t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.LONG_TAKEAWAYS_POPOVER()
              }
              placement='top'
            >
              <ClickableContainer
                onClick={(e) => setSelectedLength(Length.LONG)}
                data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.preferences.longLength}
              >
                <div className={`option ${selectedLength === Length.LONG ? 'selected' : ''}`}>
                  <NvIcon
                    icon='length-long'
                    size='small'
                    className={selectedLength === Length.LONG ? 'text-ai-color' : ''}
                  />
                  <span className='font-weight-bolder'>
                    { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.LENGTH.LONG() }
                  </span>
                </div>
              </ClickableContainer>
            </NvTooltip>
          </div>
        </div>
        {/* Tone selector */}
        <div className='tone'>
          <div className='title'>
            { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.TONE.HEADER() }
          </div>
          <div className='options d-flex'>
            <NvTooltip
              text={t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.TONE.DEFAULT_DESCRIPTION()}
              placement='top'
            >
              <ClickableContainer
                onClick={(e) => setSelectedTone(Tone.DEFAULT)}
                data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.preferences.defaultTone}
              >
                <div className={`option ${selectedTone === Tone.DEFAULT ? 'selected' : ''}`}>
                  <NvIcon
                    icon='profile'
                    size='small'
                    className={selectedTone === Tone.DEFAULT ? 'text-ai-color' : ''}
                  />
                  <span className='font-weight-bolder'>
                    { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.TONE.DEFAULT() }
                  </span>
                </div>
              </ClickableContainer>
            </NvTooltip>
            <NvTooltip
              text={t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.TONE.PROFESSIONAL_DESCRIPTION()}
              placement='top'
            >
              <ClickableContainer
                onClick={(e) => setSelectedTone(Tone.PROFESSIONAL)}
                data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.preferences.professionalTone}
              >
                <div className={`option ${selectedTone === Tone.PROFESSIONAL ? 'selected' : ''}`}>
                  <NvIcon
                    icon='job-title'
                    size='small'
                    className={selectedTone === Tone.PROFESSIONAL ? 'text-ai-color' : ''}
                  />
                  <span className='font-weight-bolder'>
                    { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.TONE.PROFESSIONAL() }
                  </span>
                </div>
              </ClickableContainer>
            </NvTooltip>
            <NvTooltip
              text={t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.TONE.FRIENDLY_DESCRIPTION()}
              placement='top'
            >
              <ClickableContainer
                onClick={(e) => setSelectedTone(Tone.FRIENDLY)}
                data-qa={config.pendo.novoAi.summaryAndKeyTakeAways.preferences.friendlyTone}
              >
                <div className={`option ${selectedTone === Tone.FRIENDLY ? 'selected' : ''}`}>
                  <NvIcon
                    icon='heart-outline'
                    size='small'
                    className={selectedTone === Tone.FRIENDLY ? 'text-ai-color' : ''}
                  />
                  <span className='font-weight-bolder'>
                    { t.LECTURE_PAGES.LHS.NOVO_AI.PREFERENCES_SELECTION.TONE.FRIENDLY() }
                  </span>
                </div>
              </ClickableContainer>
            </NvTooltip>
          </div>
        </div>
      </div>
      <GenerateButton text={novoAIProperties.lectureComponentToReplace ? t.LECTURE_PAGES.LHS.NOVO_AI.REGENERATE() : undefined} onClick={generate} />
    </React.Fragment>
  );
};

export default Preferences;
