import { createReducer, Draft, current } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import {
  addNewComponent, addTemporaryComponent, clearTemporaryComponents,
  copyLectureComponent, deleteLectureComponent, moveLectureComponent,
  reorderLectureComponent, resetLectureComponentReorder,
  setCurrentCourseCourseLongTeamSet, setCurrentCourseGroupTeamSet,
  setCurrentCourseProfileRequirement,
  unsetCurrentCourseCourseLongTeamSet, unsetCurrentCourseGroupTeamSet,
  deleteLectureComponentsByType, unsetCurrentCourseProfileRequirement, updateLectureComponentOrder,
  setActivityProgress,
  updateLectureComponentFromAngular,
  setCopyOrMoveAction,
  setAutoGenerateProcess,
  setCurrentVideoId,
  updateAutoGeneratedTranscript,
  updateAutoGeneratedCaption,
  updateAutoTranslatedCaption,
  resetNewComponent,
  setIsVideoFromLibrary,
} from 'redux/actions/lecture-components';
import { RootState } from 'redux/schemas';
import { LectureComponentSchema } from 'redux/schemas/api/lecture-components';
import { LectureComponent, NLectureComponent, ComponentType } from 'redux/schemas/models/lecture-component';
import { CollectionLecturePage, NLecturePage, NovoAIItemType, PreventNavigationReason } from 'redux/schemas/models/lecture-page';
import mergeWith from 'lodash/mergeWith';
import _, { isEmpty } from 'underscore';
import { replaceArrays } from 'shared/lodash-utils';
import { VideoPracticeFeedbackActivity } from 'redux/schemas/models/video-practice-feedback';
import { ActivityType } from 'redux/schemas/models/activity';
import { DeepPartial } from 'utility-types';
import { initialRootState } from '.';

/** Recalculates & saves the correct index for each component in a lecture page based on ther position
 * in the lecturePage's `lectureComponents` list */
const reindexLecturePage = (state: Draft<RootState>, lecturePage: NLecturePage | CollectionLecturePage) => {
  // const savedComponents = lecturePage.lectureComponents.map(lcId => state.models.lectureComponents[lcId]).sort(lc => lc.index);
  // const temporaryComponents = _.clone(Object.values(state.app.lecturePage.temporaryComponents)).sort(tc => tc.index);

  // Update the indexes on each component owned by the target lecture epage
  lecturePage.lectureComponents.forEach((lcIndex, i) => {
    state.models.lectureComponents[lcIndex].index = i;
  });
};

/** Adds a new lecture component to redux & updates the appropriate component list in the
 * lecture page & reindexes that page's components.
 *
 * TODO: This was abstracted because I believe I wanted to do this in copyLectureComponent, but
 * that actually breaks the site when you've yet to load in that page. New lecture page data
 * will load anyway after page transition. Let's reconsider whether we want this when doing the
 * outline update & page caching work. */
const addComponentToRedux = (state: Draft<RootState>, lectureComponent: LectureComponent) => {
  const normedSchema = normalize(lectureComponent, LectureComponentSchema);

  // TODO: I think we have a bug here where we need to identify & add any other normalized objects
  // found on this normedSchema object
  const normedComponent = normedSchema.entities.lectureComponents[normedSchema.result];

  // Update the list of components for the target lecture page
  state.models.lecturePages[normedComponent.lecturePageId].lectureComponents.splice(lectureComponent.index, 0, lectureComponent.id);

  // Merge the lecture component and all attached objects (exercises, external tools, videos, etc) into Redux
  // This should be 100% safe since these are guaranteed to be new entities since this is the reducer
  // for new components
  mergeWith(state.models, normedSchema.entities);

  reindexLecturePage(state, state.models.lecturePages[normedComponent.lecturePageId]);
};

const deleteComponentFromRedux = (state: Draft<RootState>, lectureComponent: DeepPartial<LectureComponent> | Partial<NLectureComponent>) => {
  const lecturePage = state.models.lecturePages[lectureComponent.lecturePageId];
  // Delete the component's id from the lecture page's ID list
  // Splice won't work here as lectureComponent.index is not reindexing in the
  // backend after a move to another component
  lecturePage.lectureComponents = lecturePage.lectureComponents.filter((lcId) => lcId !== lectureComponent.id);
  // Delete the component outright from Redux
  delete state.models.lectureComponents[lectureComponent.id];

  /**
   * Removing the deleted component from the peerEvaluations if it is used in
   * any peerEvaluations. This will update on redux when exiting the edit mode
   * due to the lecture page API request, but here updating the peer evaluation
   * for handling the edit mode.
   */
  if (lectureComponent.type === ComponentType.EXERCISE) {
    const addedEvaluation = Object.values(state.models.peerEvaluations).find(
      (evaluation: any) => evaluation.exerciseId === lectureComponent.exercise,
    );

    if (!isEmpty(addedEvaluation)) {
      const evaluationExerciseFields = {
        exerciseId: null,
        exercise: null,
        requiredExercise: null,
      };

      state.models.peerEvaluations[addedEvaluation.id] = {
        ...addedEvaluation,
        ...evaluationExerciseFields,
      };
    }

    // Consider the rating activities
    const skillsRatingActivity = Object.values(state.models.exerciseSkillsRatingActivities).find(
      (ratingActivity: any) => ratingActivity.activity?.id === lectureComponent.exercise,
    );

    if (!isEmpty(skillsRatingActivity)) {
      state.models.exerciseSkillsRatingActivities[skillsRatingActivity.id].activity = null;
    }
  }

  /**
   * This is same like the above peerEvaluations case. Here removing the deleted
   * video practice activity from the practiceFeedbackActivities if it used in
   * any practiceFeedbackActivities.
   */
  if (lectureComponent.type === ComponentType.VIDEO_PRACTICE) {
    const feedbackActivities = { ...state.models.practiceFeedbackActivities, ...state.models.exerciseSkillsRatingActivities };
    const addedPracticeFeedback = Object.values(feedbackActivities).find(
      (practiceFeedback: VideoPracticeFeedbackActivity) => practiceFeedback.activityId === lectureComponent.practiceActivity,
    );

    if (!isEmpty(addedPracticeFeedback)) {
      const practiceActivityFields = {
        activity: null,
        activityId: null,
      };

      if (addedPracticeFeedback.type === ActivityType.EXERCISE_SKILLS_RATING) {
        state.models.exerciseSkillsRatingActivities[addedPracticeFeedback.id] = {
          ...addedPracticeFeedback,
          ...practiceActivityFields,
        };
      } else {
        state.models.practiceFeedbackActivities[addedPracticeFeedback.id] = {
          ...addedPracticeFeedback,
          ...practiceActivityFields,
        };
      }
    }
  }

  reindexLecturePage(state, lecturePage);
};

const deleteComponentsByTypeFromRedux = (state: Draft<RootState>, lecturePageId: number, type: ComponentType) => {
  const lecturePage = state.models.lecturePages[lecturePageId];
  // Delete the component's ids from the lecture page's ID list when the component has the given type.
  lecturePage.lectureComponents = lecturePage.lectureComponents.filter((lcIndex) => {
    const lectureComponent = state.models.lectureComponents[lcIndex];
    if (lectureComponent.trueType === type) {
      // Delete the component outright from Redux
      delete state.models.lectureComponents[lectureComponent.id];
    }
    return lectureComponent.trueType !== type;
  });
  reindexLecturePage(state, lecturePage);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(addNewComponent.pending, (state, action) => {
      const aiAction = action.meta.arg.lectureComponent.aiProperties?.aiAction;

      // Setting a "Generating" status to show a special component, instead of showing the modal when generating components with AI
      if (action.meta.arg.lectureComponent.aiProperties) {
        state.app.newGeneratedAIComponent = {
          index: action.meta.arg.index,
          isNew: true,
          itemType: aiAction,
        };
      }

      if (!action.meta.arg.temporaryComponentId && aiAction === NovoAIItemType.DISCUSSION) {
        // Avoid showing the overlay if a temp component is being shown for the new component because it already prevents the unsightly flash-of-empty-content that we're hiding with the overlay
        state.app.showSavingOverlay = true;
      }
    })
    .addCase(addNewComponent.fulfilled, (state, action) => {
      if (action.meta.arg.lectureComponent.aiProperties) {
        state.app.newGeneratedAIComponent = null;
      }

      state.app.showSavingOverlay = false;
      const lectureComponent = action.payload;
      lectureComponent.isPristine = true;

      addComponentToRedux(state, lectureComponent);
      state.app.newComponent = lectureComponent.id;

      // Remove the associated temporary component, if any
      if (action.meta.arg.temporaryComponentId) {
        delete state.app.lecturePage.temporaryComponents[action.meta.arg.temporaryComponentId];
      }
    })
    .addCase(addNewComponent.rejected, (state, action) => {
      state.app.newGeneratedAIComponent = null;
    })
    .addCase(resetNewComponent, (state) => {
      state.app.newComponent = null;
    })
    .addCase(addTemporaryComponent, (state, action) => {
      state.app.lecturePage.temporaryComponents[action.payload.id] = action.payload;
    })

    .addCase(clearTemporaryComponents, (state, action) => {
      state.app.lecturePage.temporaryComponents = {};
    })

    // TODO: Handle the started case
    .addCase(deleteLectureComponent.fulfilled, (state, action) => {
      deleteComponentFromRedux(state, action.meta.arg.lectureComponent);
      // const { lectureComponent } = action.meta.arg;
      // const lecturePage = state.models.lecturePages[action.meta.arg.lecturePageId];
      // // Delete the component's id from the lecture page's ID list
      // lecturePage.lectureComponents.splice(lectureComponent.index, 1);
      // // Delete the component outright from Redux
      // delete state.models.lectureComponents[lectureComponent.id];

      // reindexLecturePage(state, lecturePage);
    })
    .addCase(setCopyOrMoveAction, (state, action) => {
      state.app.destinationLecturePage.action = action.payload.action;
      state.app.destinationLecturePage.component = action.payload.component;
    })
    .addCase(copyLectureComponent.fulfilled, (state, action) => {
      // const normedSchema = normalize(action.payload, LectureComponentSchema);
      const lectureComponent: any = action.payload;

      if (lectureComponent.trueType === 'ExerciseLectureComponent') {
        state.app.lecturePage.exercises.push(lectureComponent.exercise);
      }
      // TODO: I think we have a bug here where we need to identify & add any other normalized objects
      // found on this normedSchema object
      // const normedComponent = normedSchema.entities.lectureComponents[normedSchema.result];
      // state.models.lectureComponents[action.payload.id] = normedComponent;
      addComponentToRedux(state, lectureComponent);
    })
    .addCase(moveLectureComponent.fulfilled, (state, action) => {
      deleteComponentFromRedux(state, action.meta.arg.lectureComponent);
      // TODO: We don't need to do the add here since a page transition will occur anyhow. But maybe we
      // should attempt doing it anyway in case it does already exist? Let's revisit when working on
      // data caching
      // addComponentToRedux(state, action.payload);
    })
    .addCase(reorderLectureComponent, (state, action) => {
      const lecturePage = state.models.lecturePages[action.payload.lecturePageId];
      const lcIds = lecturePage.lectureComponents;

      if (!state.app.lecturePage.preventPageNavigation
        || state.app.lecturePage.preventPageNavigation !== PreventNavigationReason.REORDER_IS_DIRTY) {
        // That means this is first reorder
        state.app.lecturePage.lectureComponentsOrderCopy = _.clone(lecturePage.lectureComponents);
      }

      // Remove the existing lecture component from the IDs list
      lcIds.splice(action.payload.lectureComponent.index, 1);
      // Add it to the new location
      lcIds.splice(action.payload.newIndex, 0, action.payload.lectureComponent.id);

      reindexLecturePage(state, lecturePage);

      // Set the reorder dirty flag for lecturePage
      state.app.lecturePage.preventPageNavigation = PreventNavigationReason.REORDER_IS_DIRTY;
    })
    .addCase(resetLectureComponentReorder, (state, action) => {
      if (state.app.lecturePage.preventPageNavigation === PreventNavigationReason.REORDER_IS_DIRTY) {
        const lecturePage = state.models.lecturePages[action.payload.lecturePageId];
        lecturePage.lectureComponents = state.app.lecturePage.lectureComponentsOrderCopy;

        reindexLecturePage(state, lecturePage);
        state.app.lecturePage.preventPageNavigation = null;
      }
    })
    .addCase(setCurrentCourseGroupTeamSet, (state, action) => {
      const currentCourse = state.models.courses[state.app.currentCatalogId];
      currentCourse.hasGroups = true;
      currentCourse.groupTeamSet = action.payload.groupTeamSet;
      currentCourse.groupName = action.payload.groupTeamSet.name;
    })
    .addCase(unsetCurrentCourseGroupTeamSet, (state, action) => {
      const currentCourse = state.models.courses[state.app.currentCatalogId];
      currentCourse.groupTeamSet = null;
      // TODO: The angular lecture page *doesn't* reset this, meaning the UI will show the alias for the old
      // group formation. Uncomment this line and then find a way to reset the aliases to the default in order to
      // fix this
      // currentCourse.groupName = null;
    })
    .addCase(setCurrentCourseCourseLongTeamSet, (state, action) => {
      const currentCourse = state.models.courses[state.app.currentCatalogId];
      currentCourse.hasCourseLongTeamSet = true;
      currentCourse.courseLongTeamSet = action.payload.courseLongTeamSet;
      currentCourse.teamName = action.payload.courseLongTeamSet.teamSet.name;
    })
    .addCase(unsetCurrentCourseCourseLongTeamSet, (state, action) => {
      const currentCourse = state.models.courses[state.app.currentCatalogId];
      currentCourse.hasCourseLongTeamSet = false;
      currentCourse.courseLongTeamSet = null;
    })
    .addCase(deleteLectureComponentsByType, (state, action) => {
      deleteComponentsByTypeFromRedux(state, action.payload.lecturePageId, action.payload.type);
    })
    .addCase(setCurrentCourseProfileRequirement, (state, action) => {
      const currentCourse = state.models.courses[state.app.currentCatalogId];
      currentCourse.profileRequirement = action.payload.profileRequirement;
    })
    .addCase(unsetCurrentCourseProfileRequirement, (state, action) => {
      const currentCourse = state.models.courses[state.app.currentCatalogId];
      currentCourse.profileRequirement = null;
    })
    .addCase(setActivityProgress, (state, action) => {
      const { progress, pointsReceived, activityKey, activityPayloadId } = action.payload;

      const source: any = {
        progress,
      };

      if (pointsReceived !== undefined) {
        source.pointsReceived = pointsReceived;
      }
      if (!state.models[activityKey]?.[activityPayloadId]) {
        state.models[activityKey][activityPayloadId] = {};
      }
      Object.assign(state.models[activityKey][activityPayloadId], source);
    })
    .addCase(setCurrentVideoId, (state, { payload }) => {
      state.app.currentVideoId = payload.videoId;
    })
    .addCase(setIsVideoFromLibrary, (state, { payload }) => {
      state.app.isVideoFromLibrary = payload.isVideoFromLibrary;
    })
    .addCase(setAutoGenerateProcess, (state, { payload }) => {
      state.app.autoGenerateProcess[payload.componentType] = payload.params;
    })
    .addCase(updateAutoGeneratedTranscript, (state, { payload }) => {
      const video = state.models.video[payload.params.videoId || state.app.currentVideoId];
      if (video?.autoGenerated?.transcript) {
        video.autoGenerated.transcript = _.omit(payload.params, 'videoId');
      }
    })
    .addCase(updateAutoGeneratedCaption, (state, { payload }) => {
      const video = state.models.video[payload.params.videoId || state.app.currentVideoId];
      if (video?.autoGenerated?.caption) {
        video.autoGenerated.caption = _.omit(payload.params, 'videoId');
      }
    })
    .addCase(updateAutoTranslatedCaption, (state, { payload }) => {
      const video = state.models.video[payload.params.videoId || state.app.currentVideoId];
      if (video?.autoTranslated?.caption) {
        video.autoTranslated.caption = _.omit(payload.params, 'videoId');
      }
    })
    .addCase(updateLectureComponentOrder.fulfilled, (state, action) => {
      state.app.lecturePage.preventPageNavigation = null;
    })
    .addCase(updateLectureComponentFromAngular, (state, { payload }) => {
      const normalized = normalize(payload, LectureComponentSchema);

      if (normalized.result === undefined) {
        delete normalized.entities.lectureComponents.undefined;
      }

      mergeWith(state.models, normalized.entities, replaceArrays);
    });
});
