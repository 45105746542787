import { uniq, has } from 'underscore';
import mergeWith from 'lodash/mergeWith';
import {
  setCourses,
  loadCourse,
  getTeachingTeamMembers,
  deleteCourse,
  setCurrentCatalogId,
  unsetCurrentCatalogId,
  reenrollCourse,
  getFlyoutCurrentLearning,
  getFlyoutMyMentees,
  getFlyoutMemberships,
  addCurrentCourseMyLearning,
  getFlyoutMembershipsCurrentCourse,
  updateCourseFromAngular,
  patchCourse,
  updateCoursePrimaryStatus,
  removeCohortStatus,
  setPerCourseLicense,
  getActiveStudentsForCourse,
  setShowCourseCompletionStatusModal,
  fetchCourseCompletionStatus,
  getCourseCompletionCriteria,
  fetchDiscovery,
  fetchFeaturedDiscovery,
  unsetDiscoveryParams,
} from 'redux/actions/courses';
import { Course, CourseUsedFor } from 'redux/schemas/models/course';
import { courseSchema, discoveryOfferingsSchema } from 'redux/schemas/api/course';
import { normalize } from 'normalizr';
import { createReducer, current } from '@reduxjs/toolkit';
import { basicEnrollmentSchema, basicEnrollmentsSchema, basicMembershipsSchema, basicMenteesSchema, userListSchema } from 'redux/schemas/api/my-account';
import { replaceArrays } from 'shared/lodash-utils';
import { areObjectsModified } from 'shared/utils';
import { initialCourseCommunicationForCourse, initialCourseCompletionStatus, initialInfiniteLoading, initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(setCourses, (state, action) => {
      const allEntities = action.payload.reduce((acc, currentCourse) => {
        const normalizedData: any = normalize(currentCourse, courseSchema);

        const journey = normalizedData.entities.courses[normalizedData.result];

        if (journey.isJourney && journey.collectionIds) {
          // Manually normalization because using recursive schemas doesn't work.
          journey.collectionIds.forEach((collectionId) => {
            normalizedData.entities.journeyCollections[collectionId].courseIds = [];
            normalizedData.entities.journeyCollections[collectionId].courses.forEach((course) => {
              const { entities } = normalize(course, courseSchema);

              normalizedData.entities.journeyCollections[collectionId].courseIds.push(course.id);

              mergeWith(acc, entities, replaceArrays);
            });

            delete normalizedData.entities.journeyCollections[collectionId].courses;
          });
        }

        mergeWith(acc, normalizedData.entities, replaceArrays);
        return acc;
      }, {});

      mergeWith(state.models, allEntities, replaceArrays);
    })
    .addCase(loadCourse, (state, action) => {
      const data = normalize(action.payload, courseSchema);
      mergeWith(state.models, data.entities, replaceArrays);
      state.app.currentCourseId = action.payload.id;
      state.app.currentCatalogId = action.payload.catalogId;
      state.app.courseCommunications[action.payload.catalogId] = initialCourseCommunicationForCourse;

      // If this a new course, and if the myAccount is already fetched, the
      // redux won't be having this new course key-ed with id.
      // To make things to work with currentCourseId, set the course object
      // with id key too (if it is not set already).
      if (!has(state.models.courses, state.app.currentCourseId.toString())) {
        state.models.courses[state.app.currentCatalogId] = state.models.courses[action.payload.catalogId];
      }
    })
    .addCase(getTeachingTeamMembers.fulfilled, (state, action) => {
      const data = normalize(action.payload, userListSchema);

      mergeWith(state.models.users, data.entities.users, replaceArrays);

      const course = state.models.courses[action.meta.arg.catalogId];
      course.courseUserIds = uniq([...course.courseUserIds ?? [], ...Object.keys(data.entities.users ?? []).map(Number) ?? []]);
    })
    .addCase(getActiveStudentsForCourse.fulfilled, (state, action) => {
      const data = normalize(action.payload, userListSchema);

      mergeWith(state.models.users, data.entities.users, replaceArrays);

      const course = state.models.courses[action.meta.arg.catalogId];
      course.courseUserIds = uniq([...course.courseUserIds ?? [], ...Object.keys(data.entities.users ?? []).map(Number) ?? []]);
    })
    .addCase(deleteCourse.fulfilled, (state, action) => {
      (state.models.courses[action.meta.arg.courseCatalogId] as Course).isBeingDeleted = true;
    })
    .addCase(deleteCourse.pending, (state, action) => {
      (state.models.courses[action.meta.arg.courseCatalogId] as Course).isBeingDeleted = true;
    })
    .addCase(deleteCourse.rejected, (state, action) => {
      (state.models.courses[action.meta.arg.courseCatalogId] as Course).isBeingDeleted = false;
    })
    .addCase(setCurrentCatalogId, (state, action) => {
      const {
        id,
        catalogId,
      } = action.payload;

      state.app.currentCourseId = id;
      state.app.currentCatalogId = catalogId;
    })
    .addCase(unsetCurrentCatalogId, (state) => {
      state.app.currentCourseId = null;
      state.app.currentCatalogId = null;
    })
    .addCase(reenrollCourse.fulfilled, (state, action) => {
      mergeWith(state.models.enrollments, {
        [action.payload.id]: action.payload,
      }, replaceArrays);
    })
    .addCase(getFlyoutMemberships.rejected, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.myTeamsAndGroups.loading = false;
      } else {
        state.app.myTeamsAndGroups.loadingMore = false;
      }
      state.app.myTeamsAndGroups.firstPageLoaded = true;
    })
    .addCase(getFlyoutMemberships.pending, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.myTeamsAndGroups.loading = true;
      } else {
        state.app.myTeamsAndGroups.loadingMore = true;
      }
    })
    .addCase(getFlyoutMemberships.fulfilled, (state, action) => {
      const data = normalize(action.payload, basicMembershipsSchema);
      const groupIds = uniq(action.payload.map(membership => membership.team.courseId));
      // add new memberships to the model as we are paginating.
      mergeWith(state.models.basicMemberships, data.entities.basicmembership, replaceArrays);

      if (action.meta.arg.page === 1) {
        state.app.myTeamsAndGroups.loading = false;
        state.app.myTeamsAndGroups.firstPageLoaded = true;
      } else {
        state.app.myTeamsAndGroups.loadingMore = false;
      }
      state.app.myTeamsAndGroups.currentPage = action.meta.arg.page + 1;
      state.app.myTeamsAndGroups.hasMore = action.payload.length === action.meta.arg.page_size;
      state.app.myTeamsAndGroups.ids = uniq([...state.app.myTeamsAndGroups.ids, ...groupIds]);
    })
    .addCase(getFlyoutMembershipsCurrentCourse.fulfilled, (state, action) => {
      const data = normalize(action.payload, basicMembershipsSchema);
      const groupIds = uniq(action.payload.map(membership => membership.team.courseId));
      mergeWith(state.models.basicMemberships, data.entities.basicmembership, replaceArrays);
      state.app.myTeamsAndGroups.ids = uniq([...state.app.myTeamsAndGroups.ids, ...groupIds]);
    })
    .addCase(getFlyoutCurrentLearning.rejected, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.myLearning.loading = false;
      } else {
        state.app.myLearning.loadingMore = false;
      }
      state.app.myLearning.firstPageLoaded = true;
    })
    .addCase(getFlyoutCurrentLearning.pending, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.myLearning.loading = true;
      } else {
        state.app.myLearning.loadingMore = true;
      }
    })
    .addCase(getFlyoutCurrentLearning.fulfilled, (state, action) => {
      const data = normalize(action.payload, basicEnrollmentsSchema);
      // add new enrollments to the model as we are paginating.
      mergeWith(state.models.basicEnrollments, data.entities.basicenrollment, replaceArrays);
      if (action.meta.arg.page === 1) {
        state.app.myLearning.loading = false;
        state.app.myLearning.firstPageLoaded = true;
      } else {
        state.app.myLearning.loadingMore = false;
      }
      state.app.myLearning.currentPage = action.meta.arg.page + 1;
      state.app.myLearning.hasMore = action.payload.length === action.meta.arg.page_size;
      // append new ids to the list.
      state.app.myLearning.ids = uniq([...state.app.myLearning.ids, ...data.result]);
    })
    .addCase(getFlyoutMyMentees.rejected, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.myMentees.loading = false;
      } else {
        state.app.myMentees.loadingMore = false;
      }
      state.app.myMentees.firstPageLoaded = true;
    })
    .addCase(getFlyoutMyMentees.pending, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.myMentees.loading = true;
      } else {
        state.app.myMentees.loadingMore = true;
      }
    })
    .addCase(getFlyoutMyMentees.fulfilled, (state, action) => {
      const data = normalize(action.payload, basicMenteesSchema);

      // add new mentees to the model as we are paginating.
      mergeWith(state.models.basicMentees, data.entities.basicmentee, replaceArrays);

      if (action.meta.arg.page === 1) {
        state.app.myMentees.loading = false;
        state.app.myMentees.firstPageLoaded = true;
      } else {
        state.app.myMentees.loadingMore = false;
      }

      state.app.myMentees.currentPage = action.meta.arg.page + 1;
      state.app.myMentees.hasMore = action.payload.length === action.meta.arg.page_size;

      // append new ids to the list.
      state.app.myMentees.ids = uniq([...state.app.myMentees.ids, ...data.result]);
    })
    .addCase(addCurrentCourseMyLearning, (state, action) => {
      // special case for flyout to add the current course to the list if it's not on the first pages of results
      const data = normalize(action.payload, basicEnrollmentSchema);
      // merge current enrollment and add to the ids
      if (!state.models.basicEnrollments[action.payload.id]) {
        mergeWith(state.models.basicEnrollments, data.entities.basicenrollment, replaceArrays);
        state.app.myLearning.ids = uniq([...state.app.myLearning.ids, action.payload.id]);
      }
    })
    .addCase(fetchDiscovery.rejected, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.discovery.loading = false;
      } else {
        state.app.discovery.loadingMore = false;
      }
      state.app.discovery.firstPageLoaded = true;
    })
    .addCase(fetchDiscovery.pending, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.app.discovery.loading = true;
      } else {
        state.app.discovery.loadingMore = true;
      }
    })
    .addCase(fetchDiscovery.fulfilled, (state, action) => {
      const data = normalize(action.payload.items, discoveryOfferingsSchema);
      mergeWith(state.models.discoveryOfferings, data.entities.discoveryOffering, replaceArrays);
      if (action.meta.arg.page === 1) {
        state.app.discovery.loading = false;
        state.app.discovery.firstPageLoaded = true;
      } else {
        state.app.discovery.loadingMore = false;
      }
      state.app.discovery.currentPage = action.meta.arg.page + 1;
      state.app.discovery.hasMore = action.payload.hasMore;
      state.app.discovery.total = action.payload.total;

      const filtersModified = areObjectsModified(current(state.app.discovery.filters), action.meta.arg.filters);

      if (!filtersModified) {
        if (state.app.discovery.query === action.meta.arg.query) {
          state.app.discovery.ids = uniq([...state.app.discovery.ids, ...data.result]);
        } else {
          state.app.discovery.query = action.meta.arg.query;
          state.app.discovery.ids = uniq([...data.result]);
        }
      } else {
        state.app.discovery.filters = { ...action.meta.arg.filters };
        state.app.discovery.ids = uniq([...data.result]);
      }
    })
    .addCase(unsetDiscoveryParams, (state) => {
      state.app.discovery = initialInfiniteLoading;
    })
    .addCase(fetchFeaturedDiscovery.rejected, (state, action) => {
      state.app.featuredDiscovery.loading = false;
    })
    .addCase(fetchFeaturedDiscovery.pending, (state, action) => {
      state.app.featuredDiscovery.loading = true;
    })
    .addCase(fetchFeaturedDiscovery.fulfilled, (state, action) => {
      state.app.featuredDiscovery.loading = false;
      state.app.featuredDiscovery.offerings = action.payload.items;
    })
    .addCase(updateCourseFromAngular, (state, action) => {
      const data = normalize(action.payload, courseSchema);
      mergeWith(state.models, data.entities, replaceArrays);
    })
    .addCase(patchCourse.fulfilled, (state, action) => {
      mergeWith(state.models.courses[action.meta.arg.catalogId], action.meta.arg);
    })
    .addCase(updateCoursePrimaryStatus.fulfilled, (state, action) => {
      const course = state.models.courses[action.meta.arg.catalogId];
      course.usedFor = action.meta.arg.usedFor;
      course.isPrimary = course.usedFor === CourseUsedFor.PRIMARY;

      if (course.isPrimary) {
        course.numCohorts = 0;
      }
    })
    .addCase(removeCohortStatus.fulfilled, (state, action) => {
      const cohort = state.models.courses[action.meta.arg];
      const primary = state.models.courses[cohort.primary.catalogId];

      cohort.isCohort = false;
      cohort.primary = null;

      if (primary) {
        primary.numCohorts -= 1;
      }
    })
    .addCase(setPerCourseLicense.fulfilled, (state, action) => {
      const course = state.models.courses[action.meta.arg.catalogId];
      course.licensedByInstance = action.meta.arg.licensedByInstance;
    })
    .addCase(setShowCourseCompletionStatusModal, (state, action) => {
      state.app.courseCompletionStatus.modal.show = action.payload;

      // Reset the state when the course completion status modal is closed
      if (!action.payload) {
        state.app.courseCompletionStatus = initialCourseCompletionStatus;
      }
    })
    .addCase(fetchCourseCompletionStatus.pending, (state, action) => {
      state.app.courseCompletionStatus.modal.isLoading = true;
    })
    .addCase(fetchCourseCompletionStatus.fulfilled, (state, action) => {
      state.app.courseCompletionStatus = {
        modal: {
          show: state.app.courseCompletionStatus.modal.show,
          isLoading: false,
        },
        ...action.payload,
      };
    })
    .addCase(getCourseCompletionCriteria.fulfilled, (state, action) => {
      const course = state.models.courses[action.meta.arg.catalogId];
      course.automaticCompletionCriteria = action.payload;
      course.automatedCompletionsEnabled = action.payload?.automatedCompletionsEnabled;
    });
});
