import { css } from '@emotion/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { setLeftPanelView } from 'redux/actions/lecture-pages';
import { ComponentType, ComponentTrueType, ExternalToolType } from 'redux/schemas/models/lecture-component';
import { LecturePageState } from 'redux/schemas/models/lecture-page';
import { getFlatCourseAliases, getCurrentCourseCourseLongTeamSet, getCurrentCourseGroupTeamSet, getCurrentCourseProfileRequirement, getExerciseDependantComponentExclusions, isVideoPracticeFeedbackDisabled } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { gray2, gray3, gray4, primary } from 'styles/global_defaults/colors';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import _ from 'underscore';
import { kebabCase } from 'lodash';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import useCreateLectureComponent from 'lecture_pages/hooks/use-lecture-component';
import getComponentMetadata, { ComponentMetadata } from '../data';
import { config } from '../../../../config/config.json';
import { WorkflowParams } from '../workflows';
import ConditionalWorkflow from '../workflows/conditional-workflow';
import NvGradientBorderButton from '../../../shared/components/button/nv-gradient-border-button';
import GradientAiIcon from './novo-ai/components/shared/gradient-ai-icon';

/** Data for each panel of buttons in this left panel view */
type ComponentButtonCategory = {
  title?: string,
  buttons: ComponentButton[],
};

/** Data for each UI button in this panel */
type ComponentButton = (AddComponentButton | SubviewButton) & {
  tooltip?: string,
  icon: string,
};

/** Adds a new Lecture Component of the specified type when clicked */
type AddComponentButton = {
  type: 'add',
  componentType: ComponentTrueType,
};

/** Switches the LHS subview to show the specified state when clicked */
type SubviewButton = {
  type: 'category',
  title: string,
  subview: LecturePageState['leftPanelView'],
  /** The pendo tracker tag for clicking this button. AddComponentButtons get this
   * via getComponentMetadata() instead of specifying it here */
  pendoTagName: string,
  lessonLibraryPendoTagName?: string,
};

/** Creates the buttons for the add component left panel. This is a function
 * because our translations object isn't available until after page load */
const createButtonCategories = (): ComponentButtonCategory[] => [
  {
    buttons: [
      {
        type: 'category',
        title: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION(),
        tooltip: t.LECTURE_PAGES.COMPONENTS.CONTENTS.CONTENTS_TOOLTIP(),
        icon: 'content',
        subview: 'add_content',
        pendoTagName: config.pendo.lectureEdit.contents,
        lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.contents,
      },
      {
        type: 'add',
        icon: 'video',
        componentType: ComponentType.VIDEO,
      },
      {
        type: 'add',
        icon: 'audio',
        componentType: ComponentType.AUDIO,
      },
    ],
  },
  {
    title: t.LECTURE_PAGES.LHS.PRACTICE_AND_APPLICATION(),
    buttons: [
      {
        type: 'add',
        icon: 'assignments',
        componentType: ComponentType.EXERCISE,
      },
      {
        type: 'add',
        icon: 'media-practice',
        componentType: ComponentType.VIDEO_PRACTICE,
      },
    ],
  },
  {
    title: t.LECTURE_PAGES.LHS.SOCIAL_ACTIVITIES(),
    buttons: [
      {
        type: 'add',
        icon: 'profile',
        componentType: ComponentType.PROFILE_COMPLETION,
      },
      {
        type: 'add',
        icon: 'team',
        componentType: ComponentType.TEAM_FORMATION,
      },
      {
        type: 'add',
        icon: 'groups',
        componentType: ComponentType.GROUP_FORMATION,
      },
      {
        type: 'add',
        icon: 'gallery',
        componentType: ComponentType.SUBMISSION_DISCOVERY,
      },
      {
        type: 'add',
        icon: 'conversations',
        componentType: ComponentType.TOPIC,
      },
      {
        type: 'add',
        icon: 'polls',
        componentType: ComponentType.POLL,
      },
      {
        type: 'add',
        icon: 'meet-your-peers',
        componentType: ComponentType.MEET_AND_GREET,
      },
    ],
  },
  {
    title: t.LECTURE_PAGES.LHS.FEEDBACK_ACTIVITIES(),
    buttons: [
      {
        type: 'add',
        icon: 'comments',
        componentType: ComponentType.PUBLIC_PEER_EVALUATION,
      },
      {
        type: 'add',
        icon: 'peer-feedback',
        componentType: ComponentType.VIDEO_PRACTICE_FEEDBACK,
      },
    ],
  },
  {
    title: t.LECTURE_PAGES.LHS.QUIZ_AND_SURVEY(),
    buttons: [
      {
        type: 'add',
        icon: 'quiz',
        componentType: ComponentType.QUIZ,
      },
      {
        type: 'add',
        icon: 'timedexam',
        componentType: ComponentType.TIMED_QUIZ,
      },
      {
        type: 'add',
        icon: 'survey',
        componentType: ComponentType.SURVEY,
      },
    ],
  },
  {
    title: t.LECTURE_PAGES.LHS.THIRD_PARTY_LINKS_AND_TOOLS(),
    buttons: [
      {
        type: 'add',
        icon: 'web-embeds',
        componentType: ExternalToolType.WEB_EMBED,
      },
      {
        type: 'add',
        icon: 'admin-embeds',
        componentType: ExternalToolType.WEB_LINK,
      },
      {
        type: 'add',
        icon: 'admin-scorm',
        componentType: ExternalToolType.SCORM,
      },
      {
        type: 'add',
        icon: 'admin-lti-tools',
        componentType: ExternalToolType.LTI,
      },
      {
        type: 'add',
        icon: 'zoom',
        componentType: ComponentType.LIVE_SESSION,
      },
    ],
  },
];

/**
 * This array contains the components used in the lesson library collection
 * lesson for now. New components should be added to this array when they are
 * integrated into the lesson library.
 */
const enabledLessonLibraryComponents: ComponentTrueType[] = [
  ComponentType.AUDIO,
  ComponentType.VIDEO,
  ExternalToolType.WEB_EMBED,
  ExternalToolType.WEB_LINK,
  ExternalToolType.SCORM,
  ExternalToolType.LTI,
  ComponentType.LIVE_SESSION,
  ComponentType.TOPIC,
  ComponentType.EXERCISE,
  ComponentType.QUIZ,
  ComponentType.SURVEY,
  ComponentType.TIMED_QUIZ,
  ComponentType.POLL,
  ComponentType.VIDEO_PRACTICE,
];

/** The standard subview of the 'add new component' sidebar where you can select a component to add or
 * click in further to view content-type components. */
export const AddComponentTopLevel = () => {
  const styles = css`
    .add-title {
      font-weight: 700;
    }

    .category-title {
      font-size: 12px;
      font-weight: 600;
      color: ${gray2};
    }

    .button-title {
      font-size: 12px;
      line-height: 15px;
    }

    .button-container {
      width: 100%;
      display: grid;
      grid-template-columns: 120px 120px;
      /* TODO: This may not be supported in IE11 */
      justify-content: center;
      -ms-grid-template-columns: 120px 120px;
    }

    .component-button:not(.disabled) {
      cursor: pointer;

      &:hover {
        color: ${primary};
      }
    }

    .component-button.disabled {
      .icon{
        color:${gray4};
      }
      .button-title{
        color:${gray3};
      }
    }
  `;

  const dispatch = useAppDispatch();
  const { fireCreateNewComponent } = useCreateLectureComponent();
  const { catalogId, currentCourseIsCollection } = useLecturePageParams();
  const courseAliases = useSelector(state => getFlatCourseAliases(state, catalogId));
  const [buttonCategories] = useState(createButtonCategories());
  const currentInstitution = useSelector(getCurrentInstitution);
  const exerciseDependantComponentExclusions = useSelector(getExerciseDependantComponentExclusions);

  // const currentCourseCourseLongTeamSet = useSelector(state => getCurrentCourseCourseLongTeamSet(state));
  // const currentCourseGroupTeamSet = useSelector(state => getCurrentCourseGroupTeamSet(state));
  // const currentCourseProfileRequirement = useSelector(state => getCurrentCourseProfileRequirement(state));

  const disabledTooltipParams = useSelector(state => {
    if (catalogId) {
      const currentCourseCourseLongTeamSet = getCurrentCourseCourseLongTeamSet(state);
      const currentCourseGroupTeamSet = getCurrentCourseGroupTeamSet(state);
      const currentCourseProfileRequirement = getCurrentCourseProfileRequirement(state);

      return {
        ...courseAliases,
        teamLectureName: state.models.lecturePages?.[currentCourseCourseLongTeamSet?.lecturePageId]?.title,
        groupLectureName: state.models.lecturePages?.[currentCourseGroupTeamSet?.lecturePageId]?.title,
        lectureName: state.models.lecturePages?.[currentCourseProfileRequirement?.lecturePageId]?.title,
      };
    }

    return {};
  });

  const onButtonClicked = (button: ComponentButton, metadata: ComponentMetadata) => {
    // Avoid firing standard button click behavior on modal workflows since they themselves handle
    // creating a new component
    if (metadata?.workflow.type === 'modal' || metadata?.workflow.type === 'directlyUploadedFile') {
      return;
    }

    switch (button.type) {
      case 'add':
        fireCreateNewComponent(button.componentType, metadata);
        break;
      case 'category':
        dispatch(setLeftPanelView(button.subview));
        break;
      default:
        break;
    }
  };

  const disabledComponentButtons = useSelector(state => {
    const disabledComponents: Set<ComponentTrueType> = new Set();

    if (catalogId) {
      if (getCurrentCourseCourseLongTeamSet(state)) {
        disabledComponents.add(ComponentType.TEAM_FORMATION);
      }

      if (getCurrentCourseGroupTeamSet(state)) {
        disabledComponents.add(ComponentType.GROUP_FORMATION);
      }

      if (getCurrentCourseProfileRequirement(state)) {
        disabledComponents.add(ComponentType.PROFILE_COMPLETION);
      }

      exerciseDependantComponentExclusions.forEach(exclusion => disabledComponents.add(exclusion));
    }

    if (isVideoPracticeFeedbackDisabled(state)) {
      disabledComponents.add(ComponentType.VIDEO_PRACTICE_FEEDBACK);
    }

    return disabledComponents;
  });

  const getPendoTag = (buttonCategory: ComponentMetadata | SubviewButton) => {
    if (currentCourseIsCollection && buttonCategory?.lessonLibraryPendoTagName) {
      return buttonCategory.lessonLibraryPendoTagName;
    }

    return buttonCategory?.pendoTagName;
  };

  const setNovoAiView = () => {
    dispatch(setLeftPanelView('novo_ai'));
  };

  return (
    <div css={styles} className='d-flex flex-column w-100'>
      <div className='d-flex sticky-top bg-gray-7 align-items-center p-4 border-bottom border-gray-6'>
        <div className='course-title-small mx-auto add-title'>
          {t.LECTURE_PAGES.LHS.ADD_COMPONENT_TITLE()}
        </div>
        <NvIcon
          className='text-gray-2'
          icon='close'
          size='small'
          onClick={() => hideAddUI(dispatch)}
        />
      </div>
      {!currentCourseIsCollection && currentInstitution.hasAiComponentGeneration && (
        <div className='d-flex justify-content-center border-bottom border-gray-5 p-4'>
          <NvGradientBorderButton
            onClick={setNovoAiView}
            className='align-items-center py-2 px-5'
            dataQa={config.pendo.novoAi.lhsButton}
          >
            <GradientAiIcon icon='gen-ai' size='smallest' className='pr-2' />
            <span className='text-regular bold'>
              {t.LECTURE_PAGES.LHS.NOVO_AI.START_BUTTON()}
            </span>
          </NvGradientBorderButton>
        </div>
      )}
      <div className='px-4 d-flex flex-column w-100'>
        {buttonCategories.map((category, i) => (
          <div
            key={category.title ?? 'no-title'}
            className={`pb-5 d-flex flex-column align-items-center ${(i && 'border-top border-gray-5 pt-4') ?? ''}`}
          >
            <div className='category-title'>{category.title}</div>
            <div className='button-container'>
              {category.buttons.map(button => {
                const metadata = button.type === 'add' ? getComponentMetadata(button.componentType) : null;
                const componentType = button.type === 'add' && button.componentType;
                const pendoTagName = button.type === 'add' ? getPendoTag(metadata) : getPendoTag(button);
                const title = button.type === 'add' ? metadata.description(courseAliases) : button.title;

                const isDisabled = disabledComponentButtons.has(componentType)
                  // LL-Todo: The collection level disabling can be removed once all components are integrated into the lesson library
                  || (currentCourseIsCollection && button.type === 'add' && !enabledLessonLibraryComponents.includes(componentType));

                const buttonElement = (
                  <div>
                    <NvTooltip enabled={(isDisabled || !!button.tooltip) && !currentCourseIsCollection} text={metadata?.disabledTooltip?.(disabledTooltipParams) ?? button.tooltip}>
                      <div
                        className={`${isDisabled ? 'disabled' : ''} component-button mt-5 ml-4 mr-4 d-flex flex-column align-items-center text-center`}
                        onClick={() => !isDisabled && onButtonClicked(button, metadata)}
                      /* Omit the pendo tag if it's undefined for this button */
                        pendo-tag-name={(!isDisabled && pendoTagName) || undefined}
                        data-qa={`lhs-${
                          kebabCase(button.type === 'add'
                            ? `${button.componentType}-add`
                            : `${button.title}-category`)
                        }-button`}
                      >
                        <NvIcon icon={button.icon} size='small' />
                        <div className='button-title mt-2'>
                          {title}
                        </div>
                      </div>
                    </NvTooltip>
                  </div>
                );

                const workflowProps: Omit<WorkflowParams, 'children'> = {
                  componentType,
                  metadata,
                  saveNewComponent: fireCreateNewComponent,
                  mode: 'new',
                  isDisabled,
                };

                return (
                  <NvTooltip key={title} text={button.tooltip} enabled={!!button.tooltip}>
                    <ConditionalWorkflow {...workflowProps}>
                      {buttonElement}
                    </ConditionalWorkflow>
                  </NvTooltip>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddComponentTopLevel;
