import t from 'react-translate';
import { AccordionSectionType, ComponentTrueType, ComponentType, ExternalToolType, LectureComponent, NLectureComponent, RichTextType, StyledLinkType } from 'redux/schemas/models/lecture-component';
import _ from 'underscore';
import { ExposedAngularServices } from 'react-app';
import { DeepPartial } from 'utility-types';
import ExerciseEditFormController from 'lecture_pages/controllers/exercise-edit-form-modal-controller.js';
import LiveSessionEditFormModalController from 'lecture_pages/controllers/live-session-edit-form-modal-controller.jsx';
import FeedbackEditFormModalController from 'lecture_pages/controllers/feedback-edit-form-modal-controller.js';
import ProfileCompletionEditFormModalController from 'lecture_pages/controllers/profile-completion-edit-form-modal-controller.js';
import DiscussionFormModalController from 'lecture_pages/controllers/discussion-form-modal-controller.js';
import TimedQuizEditFormModalController from 'lecture_pages/controllers/timed-quiz-edit-form-modal-controller.js';
import SubmissionsDiscoverEditFormModalController from 'lecture_pages/controllers/submissions-discovery-edit-form-modal-controller';
import VideoListEditFormController from 'lecture_pages/controllers/video-list-edit-form-modal-controller.js';
import VideoPracticeEditFormModalController from 'lecture_pages/controllers/video-practice-edit-form-modal-controller.jsx';
import VideoPracticeEvaluationCtrl from 'lecture_pages/controllers/video-practice-evaluation-controller.jsx';
import MeetAndGreetEditFormModalController from 'lecture_pages/controllers/meet-and-greet-edit-form-modal-controller.jsx';

import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import deepFreeze from 'deep-freeze';
import cloneDeep from 'lodash/cloneDeep';
import { AppDispatch } from 'redux/store';
import { setCurrentCourseCourseLongTeamSet, setCurrentCourseGroupTeamSet,
  unsetCurrentCourseCourseLongTeamSet, unsetCurrentCourseGroupTeamSet,
  deleteLectureComponentsByType, setCurrentCourseProfileRequirement,
  unsetCurrentCourseProfileRequirement,
} from 'redux/actions/lecture-components';
import { getLecturePage } from 'redux/actions/lecture-pages';
import { unsetSavingOverlayText } from 'redux/actions/saving-overlay';
import QuizModal from 'quizzes/components/quiz-modal';
import { config } from '../../../../config/config.json';
import { ComponentCreationWorkflow, WorkflowMode } from '../workflows';
import { LecturePageMode } from '..';

export type ComponentRecord = {
  [P in ComponentTrueType]: Readonly<DeepPartial<LectureComponent<P>>>;
};

/** Defines data about different kinds of components that need to be retrieved by component type. */
export type ComponentMetadata<C extends ComponentTrueType = any> = {
  workflow?: Readonly<ComponentCreationWorkflow<C>>,
  /* This is the function used to retrieve the component's human-readable name to display in confirmation
  dialogs and other places. This gets called in getComponentMetadata() so that it's evaluated as-needed
  since translations are not available immediately on page load */
  description?: (courseAliases?: object) => string;
  /** Similar to description(); only used by some components in very few places. Can we remove this? */
  descriptionLowercase?: (courseAliases?: object) => string;
  /** A pendo tag used to trigger tracking of when a component of a type is added.
   * Set on the add component buttons in the left panel */
  // To provide separate pendo tag for the lesson library
  lessonLibraryPendoTagName?: string,
  pendoTagName?: string,
  /** The text displayed in a tooltip on the LHS component add button when adding this type of component is
   * disabled */
  disabledTooltip?: (translationParams?: any) => string,
  deleteWarning?: (courseAliases?: object) => string;
  afterDelete?: (dispatch: AppDispatch, lectureComponent: NLectureComponent<C>, extraDeps?: { angularServices: ExposedAngularServices }) => void,
  /** This is a hack for our edit dropdowns not auto resizing to fit content within min and max sizes */
  // TODO: Experimental, delete
  wideEditDropdown?: boolean,
  /** Whether to disable copying on lecture component */
  copyDisabled?: boolean,
  /** Whether to display the default 'Move to' dropdown action. */
  moveDisabled?: boolean,
  /** Whether to hide the default 'Copy to' dropdown action. */
  copyHidden?: boolean,
};

/** Maps FullComponent type keys to metadata */
type ComponentMetadataRecord = {
  [P in ComponentTrueType]: Readonly<ComponentMetadata<P>>;
};

const getComponentMetadata = (componentType: ComponentTrueType) => {
  const metadata: ComponentMetadataRecord = {
    [ComponentType.ACCORDION]: {
      description: t.LECTURE_PAGES.COMPONENTS.ACCORDION.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.ACCORDION.DESCRIPTION_LOWERCASE,
      pendoTagName: '', // TODO: I can't seem to find this
      workflow: { type: 'default' },
    },
    [ComponentType.ATTACHMENT]: {
      description: t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.resources,
      workflow: {
        type: 'file',
        accept: [
          config.files.presentations,
          config.files.documents,
          config.files.pdfs,
          config.files.audios,
          config.files.videos,
          config.files.images,
          config.files.zips,
          ...config.files.allUploadableExtensions,
        ],
        s3Namespace: S3NameSpaces.ATTACHMENTS,
      },
    },
    [ComponentType.AUDIO]: {
      description: t.LECTURE_PAGES.COMPONENTS.AUDIO_LIST.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.AUDIO_LIST.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.audio,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.audio,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: t.LECTURE_AUDIO.ADD_AUDIO,
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/audio-list-edit-modal.html',
            controllerFunction: VideoListEditFormController,
            formName: 'editVideoTitleForm',
          },
        },
      },
    },
    [ComponentType.EXERCISE]: {
      description: t.LECTURE_PAGES.COMPONENTS.EXERCISE.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.EXERCISE.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.assignment,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode, courseAliases) => t.LECTURE_PAGES.COMPONENTS.EXERCISE.MODAL.INDIVIDUAL_TEAM.TITLE(courseAliases),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/exercise-edit-modal.html',
            controllerFunction: ExerciseEditFormController,
            formName: 'editExercisePrivacyForm', // TODO: This needs to be 'editExerciseApprovalForm' when working with approvals
          },
        },
        adminNavigation: (catalogId, lectureComponent) => `/${catalogId}/exercises/${lectureComponent.exercise.id}/edit_submission_format`,
        showCreateConfirmation: (lc, currentCourse, mode) => {
          if (mode === 'new'
            && lc.exercise?.teamSet?.isCourseLong
            // TODO: This is bound to be a bug if we fail to update .courseLongTeamSet from React
            && !currentCourse.courseLongTeamSet) {
            return 'lecture_pages/templates/components/exercise-creation-confirmation-overlay.html';
          }

          return null;
        },
        afterSave: (dispatch, mode, lc: any) => {
          /**
           * We are creating a new team formation component when there is no team
           * formation after creating a new team assignment component or updating
           * individual assignments to team assignments. But the created new team
           * formation component will not be available on FE.
           *
           * So requesting the lecture page API to update the components list on
           * the editing (ie, updating individual assignments to team assignments).
           *
           * Not using this API request when creating a team assignment with the
           * team formation. Because there has an admin page navigation after creation.
           */
          if (mode === 'edit' && lc?.addingTeamFormationExercise) {
            dispatch(getLecturePage({
              catalogId: lc.catalogId,
              lecturePageId: parseInt(lc.lecturePageId, 10),
              mode: LecturePageMode.EDIT,
            }));

            // Clearing the saving overlay text after creating the team formation component.
            dispatch(unsetSavingOverlayText());
          }
        },
      },
      wideEditDropdown: true,
    },
    [ExternalToolType.LTI]: {
      description: t.LECTURE_PAGES.COMPONENTS.LTI.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.LTI.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.lti,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.lti,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          // TODO: Change title on edit
          title: (mode, courseAliases) => (mode === 'new' ? t.LECTURE_PAGES.COMPONENTS.LTI.MODAL.ADD_TITLE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/lit-edit-modal.html',
            formName: 'editLTIForm',
          },
        },
      },
    },
    [ExternalToolType.SCORM]: {
      description: t.LECTURE_PAGES.COMPONENTS.SCORM.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.SCORM.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.scorm,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.scorm,
      // TODO: Verify this accept is correct
      workflow: {
        type: 'directlyUploadedFile',
        filePath: 'externalTool.file',
        isAngularLectureComponent: true,
        accept: config.files.scorm.extensions,
      },
    },
    [ExternalToolType.WEB_EMBED]: {
      description: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.WEB_LINK.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.embedLink,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.webEmbed,
      workflow: {
        type: 'modal',
        renderModal: (props) => <div>{props.children}</div>,
        initialSettings: {
          title: (mode) => (mode === 'new'
            ? t.LECTURE_PAGES.COMPONENTS.WEB_LINK.MODAL.ADD_TITLE()
            : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/embed-edit-modal.html',
            formName: 'editEmbedForm',
          },
        },
      },
    },
    [ExternalToolType.WEB_LINK]: {
      description: t.LECTURE_PAGES.COMPONENTS.NON_EMBEDDED_WEB_LINK.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.NON_EMBEDDED_WEB_LINK.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.embedLink,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.webLink,
      workflow: {
        type: 'modal',
        renderModal: (props) => <div>{props.children}</div>,
        initialSettings: {
          title: (mode) => (mode === 'new'
            ? t.LECTURE_PAGES.COMPONENTS.NON_EMBEDDED_WEB_LINK.MODAL.ADD_TITLE()
            : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/embed-edit-modal.html',
            formName: 'editEmbedForm',
          },
        },
      },
    },
    [AccordionSectionType.STYLE_1]: {
      description: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_1.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_1.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.accordion,
      workflow: { type: 'default' },
    },
    [AccordionSectionType.STYLE_2]: {
      description: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_2.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_2.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.accordion,
      workflow: { type: 'default' },
    },
    [AccordionSectionType.STYLE_3]: {
      description: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_3.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_3.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.accordion,
      workflow: { type: 'default' },
    },
    [AccordionSectionType.STYLE_4]: {
      description: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_4.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_4.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.accordion,
      workflow: { type: 'default' },
    },
    [AccordionSectionType.STYLE_5]: {
      description: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_5.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.accordion,
      workflow: { type: 'default' },
    },
    [ComponentType.GROUP_FORMATION]: {
      description: t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.groupFormation,
      disabledTooltip: (params) => t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.UNAVAILABLE(params),
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode, courseAliases) => (mode === 'edit' ? t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.MODAL.EDIT(courseAliases) : t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.MODAL.ADD(courseAliases)),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/group-formation-edit-modal.html',
            formName: 'editGroupFormationTypeForm',
          },
        },
        afterSave: (dispatch, mode, lc, extraDeps) => {
          const { angularServices } = extraDeps;
          angularServices.CurrentCourseManager.course.groupTeamSet = {
            lectureComponentId: lc.id,
            lecturePageId: lc.lecturePageId,
            ...lc.teamSet,
          };
          angularServices.CurrentCourseManager.course.groupName = lc.teamSet.name;

          const currentEnrollment = angularServices.CurrentUserManager.user.enrollments.find(
            (enrollment) => enrollment.course.id === angularServices.CurrentCourseManager.course.id,
          );
          currentEnrollment.course.groupName = lc.teamSet.name;

          dispatch(setCurrentCourseGroupTeamSet({
            groupTeamSet: {
              lectureComponentId: lc.id,
              lecturePageId: lc.lecturePageId,
              ...lc.teamSet,
            },
          }));
        },
      },
      deleteWarning: (courseAliases) => t.LECTURE_PAGES.COMPONENTS.GROUP_FORMATION.DELETION_DESCRIPTION(courseAliases),
      afterDelete: (dispatch, lc, extraDeps) => {
        const { angularServices } = extraDeps;
        angularServices.CurrentCourseManager.course.groupTeamSet = null;
        dispatch(unsetCurrentCourseGroupTeamSet());
      },
      copyDisabled: true,
    },
    [ComponentType.HEADER_STYLE1]: {
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.contents,
      workflow: { type: 'default' },
    },
    [ComponentType.HEADER_STYLE2]: {
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.contents,
      workflow: { type: 'default' },
    },
    [ComponentType.HEADER_STYLE3]: {
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.contents,
      workflow: { type: 'default' },
    },
    [ComponentType.IMAGE]: {
      description: t.LECTURE_PAGES.COMPONENTS.IMAGE.DESCRIPTION,
      // TODO: wrong; this is missing!
      pendoTagName: config.pendo.lectureEdit.audio,
      // TODO: Verify this accept is correct
      workflow: {
        type: 'file',
        accept: config.files.allImageExtensions,
        s3Namespace: S3NameSpaces.CONTENT_TEMPLATES,
      },
    },
    [ComponentType.WHITE_SPACE]: {
      moveDisabled: true,
      copyHidden: true,
      pendoTagName: config.pendo.lectureEdit.whiteSpace,
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      workflow: {
        type: 'default',
      },
    },
    [ComponentType.LINE_DIVIDER]: {
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.lineDivider,
      workflow: { type: 'default' },
    },
    [ComponentType.LIVE_SESSION]: {
      description: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.liveEvent,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.liveEvent,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: () => 'STUB_REPLACE',
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/live-session-edit-modal.html',
            controllerFunction: LiveSessionEditFormModalController,
            formName: 'editLiveSessionForm',
          },
        },
      },
    },
    [ComponentType.MEET_AND_GREET]: {
      description: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.DESCRIPTION,
      pendoTagName: 'add-meet-and-greet-lecture-component',
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: t.LECTURE_PAGES.COMPONENTS.MEET_AND_GREET.DESCRIPTION,
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/meet-and-greet-edit-modal.html',
            controllerFunction: MeetAndGreetEditFormModalController,
          },
        },
      },
    },
    // TODO
    [ComponentType.POLL]: {
      description: t.LECTURE_PAGES.COMPONENTS.POLLS.DESCRIPTION,
      pendoTagName: '',
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.poll,
      workflow: {
        type: 'default',
      },
    },
    [ComponentType.PROFILE_COMPLETION]: {
      description: t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.DESCRIPTION,
      pendoTagName: config.pendo.lectureEdit.profileCompletion,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: () => t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION_MODAL.SELECT_QUESTIONS(),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/profile-completion-edit-form-modal.html',
            controllerFunction: ProfileCompletionEditFormModalController,
          },
        },
        afterSave: (dispatch, mode, lc: any) => {
          dispatch(setCurrentCourseProfileRequirement({
            profileRequirement: lc.profileRequirement,
          }));

          if (lc.redirectToAdminNavigation) {
            window.location.href = `/${lc.catalogId}/dashboard?tab=profiles&editor-component=${lc.id}&edit-when-back=true`;
          }
        },
      },
      afterDelete: (dispatch, lc) => {
        dispatch(unsetCurrentCourseProfileRequirement());
      },
      disabledTooltip: (params) => t.LECTURE_PAGES.COMPONENTS.PROFILE_COMPLETION.UNAVAILABLE(params.lectureName),
      copyDisabled: true,
    },

    /**
     * This is not used. Now merged the private and public peer evaluations into
     * one when creating from LHS, but we are using separate types for these two.
     * So default it will be public and choose private on its creation modal.
     */
    [ComponentType.PRIVATE_PEER_EVALUATION]: {
      description: t.LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.privateFeedback,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode) => (mode === 'new' ? t.LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.ADD_FEEDBACK() : t.LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.EDIT_FEEDBACK()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/feedback-edit-modal.html',
            controllerFunction: FeedbackEditFormModalController,
          },
        },
        adminNavigation: (catalogId, lectureComponent) => `/${catalogId}/oe/#!/exercises/${lectureComponent.peerEvaluation.exercise.id}/edit_peer_info`,
      },
      disabledTooltip: (params) => t.LECTURE_PAGES.COMPONENTS.PRIVATE_PEER_EVALUATION.UNAVAILABLE(params),
    },
    // Consolidated public+private feedback
    [ComponentType.PUBLIC_PEER_EVALUATION]: {
      description: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.publicFeedback,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode, courseAliases) => (mode === 'new' ? t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.ADD(courseAliases) : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.EDIT()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/feedback-edit-modal.html',
            controllerFunction: FeedbackEditFormModalController,
          },
        },
        afterSave: (dispatch, mode, lc: any) => {
          // adminNavigation only for private evaluation
          if (mode === 'new' && lc.type === ComponentType.PRIVATE_PEER_EVALUATION) {
            window.location.href = `/${lc.peerEvaluation.catalogId}/oe/#!/exercises/${lc.peerEvaluation.exerciseId}/edit_peer_info`;
          }
        },
      },
      disabledTooltip: (params) => t.LECTURE_PAGES.COMPONENTS.PUBLIC_PEER_EVALUATION.UNAVAILABLE(params),
    },
    [ComponentType.EXERCISE_SKILLS_RATING]: {
      description: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.skillRatingForAssignment,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode, courseAliases) => (mode === 'new' ? t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.ADD(courseAliases) : t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.EDIT()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/feedback-edit-modal.html',
            controllerFunction: FeedbackEditFormModalController,
          },
        },
        afterSave: (dispatch, mode, lc: any) => {
          // adminNavigation only for private evaluation
          if (mode === 'new' && lc.type === ComponentType.PRIVATE_PEER_EVALUATION) {
            window.location.href = `/${lc.peerEvaluation.catalogId}/oe/#!/exercises/${lc.peerEvaluation.exerciseId}/edit_peer_info`;
          }
        },
      },
      disabledTooltip: (params) => t.LECTURE_PAGES.COMPONENTS.PUBLIC_PEER_EVALUATION.UNAVAILABLE(params),
      deleteWarning: () => t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.DELETE.DESCRIPTION(),
    },
    [ComponentType.QUIZ]: {
      description: t.LECTURE_PAGES.COMPONENTS.QUIZ.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.QUIZ.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.quiz,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.quiz,
      workflow: {
        type: 'modal',
        renderModal: (props) => <QuizModal {...props as any} />,
        initialSettings: {
          modalWidth: 800,
          title: (mode) => (mode === 'new' ? t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TITLE.ADD() : t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TITLE.EDIT()),
        },
      },
    },
    [ComponentType.PROGRESSIVE_QUIZ]: {
      description: t.LECTURE_PAGES.COMPONENTS.QUIZ.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.QUIZ.DESCRIPTION_LOWERCASE,
      // This workflow is only used in Gen-AI creation; otherwise, we are using the quiz workflow.
      workflow: { type: 'default' },
    },
    [RichTextType.FULL]: {
      description: t.LECTURE_PAGES.COMPONENTS.HTML.DESCRIPTION,
      pendoTagName: config.pendo.lectureEdit.richText,
      workflow: { type: 'default' },
    },
    [RichTextType.SIMPLE]: {
      description: t.LECTURE_PAGES.COMPONENTS.TEXT.DESCRIPTION,
      pendoTagName: config.pendo.lectureEdit.simpleText,
      workflow: { type: 'default' },
    },
    // TODO: I think both styled link types have exactly the same definiton here; can be merged
    [StyledLinkType.BUTTON]: {
      description: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.DESCRIPTION,
      pendoTagName: config.pendo.lectureEdit.buttonLink,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: () => t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.ENTER_URL_MODAL_TITLE(),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/styled-link-edit-modal.html',
            formName: 'editStyledLinkForm',
          },
        },
      },
    },
    [StyledLinkType.CARD]: {
      description: t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.DESCRIPTION,
      pendoTagName: config.pendo.lectureEdit.buttonLink,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: () => t.LECTURE_PAGES.COMPONENTS.STYLED_LINK.ENTER_URL_MODAL_TITLE(),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/styled-link-edit-modal.html',
            formName: 'editStyledLinkForm',
          },
        },
      },
    },
    [ComponentType.SUBMISSION_DISCOVERY]: {
      description: t.LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.carousel,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode) => (mode === 'edit'
            ? t.LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.MODAL.EDIT_TITLE()
            : t.LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.MODAL.ADD_TITLE()
          ),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/submissions-discovery-edit-modal.html',
            formName: 'editSubmissionsDiscoveryForm',
            controllerFunction: SubmissionsDiscoverEditFormModalController,
          },
        },
      },
      disabledTooltip: (params) => t.LECTURE_PAGES.COMPONENTS.SUBMISSIONS_DISCOVERY.UNAVAILABLE(params),
    },
    [ComponentType.SURVEY]: {
      description: t.LECTURE_PAGES.COMPONENTS.SURVEY.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.SURVEY.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.survey,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.survey,
      // This url is copied from rails-route.js's editQuestionSetPath()
      workflow: { type: 'admin-1.0', createUrl: (catalogId, component) => `/${catalogId}/question_sets/${component.survey.id}/edit` },
    },
    [ComponentType.TEAM_DISCUSSION]: {
      // TODO: This component doesn't exist yet
      description: () => 'Team Discussion',
      pendoTagName: '',
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: () => 'STUB_REPLACE',
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/discussion-modal.html',
            controllerFunction: DiscussionFormModalController,
          },
        },
      },
    },
    [ComponentType.TEAM_FORMATION]: {
      description: t.LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.teamFormation,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode, courseAliases) => (mode === 'edit' ? t.LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.MODAL.EDIT(courseAliases) : t.LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.MODAL.ADD(courseAliases)),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/team-formation-edit-modal.html',
            formName: 'editTeamFormationTypeForm',
          },
        },
        afterSave: (dispatch, mode, lc, extraDeps) => {
          const { angularServices } = extraDeps;
          angularServices.CurrentCourseManager.course.courseLongTeamSet = {
            lectureComponentId: lc.id,
            lecturePageId: lc.lecturePageId,
            ...lc.teamSet,
          };
          angularServices.CurrentCourseManager.course.teamName = lc.teamSet.name;

          const currentEnrollment = angularServices.CurrentUserManager.user.enrollments.find(
            (enrollment) => enrollment.course.id === angularServices.CurrentCourseManager.course.id,
          );
          currentEnrollment.course.teamName = lc.teamSet.name;

          dispatch(setCurrentCourseCourseLongTeamSet({
            courseLongTeamSet: {
              id: lc.id,
              lectureComponentId: lc.id,
              lecturePageId: lc.lecturePageId,
              teamSet: { ...lc.teamSet },
            },
          }));
        },
      },
      deleteWarning: (courseAliases) => t.LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.DELETION_DESCRIPTION(courseAliases),
      afterDelete: (dispatch, lc, extraDeps) => {
        const { angularServices } = extraDeps;
        angularServices.CurrentCourseManager.course.courseLongTeamSet = null;
        dispatch(unsetCurrentCourseCourseLongTeamSet());
        dispatch(deleteLectureComponentsByType({
          lecturePageId: lc.lecturePageId,
          type: ComponentType.TEAM_DISCUSSION,
        }));
      },
      disabledTooltip: (params) => t.LECTURE_PAGES.COMPONENTS.TEAM_FORMATION.UNAVAILABLE(params),
      copyDisabled: true,
    },
    [ComponentType.TEXT_WITH_IMAGE_BKG]: {
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.pictureBackgroundText,
      workflow: { type: 'default' },
    },
    [ComponentType.TEXT_WITH_IMAGE_SIDE]: {
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.pictureSideText,
      workflow: { type: 'default' },
    },
    [ComponentType.TEXT_WITH_IMAGE_TOP]: {
      description: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.CONTENTS.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.pictureAboveText,
      workflow: { type: 'default' },
    },
    [ComponentType.TIMED_QUIZ]: {
      description: t.LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.timedExam,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.timedQuiz,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode) => (mode === 'new' ? t.LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.MODAL.TITLE.ADD() : t.LECTURE_PAGES.COMPONENTS.TIMED_QUIZ.MODAL.TITLE.EDIT()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/timed-quiz-edit-modal.html',
            controllerFunction: TimedQuizEditFormModalController,
            formName: 'editTimedQuizForm',
          },
        },
        adminNavigation: (catalogId, lectureComponent) => `/${catalogId}/question_sets/${lectureComponent.quiz.id}/edit`,
      },
    },
    [ComponentType.TOPIC]: {
      description: t.LECTURE_PAGES.COMPONENTS.DISCUSSION.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.DISCUSSION.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.discussion,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: (mode) => (mode === 'new' ? t.LECTURE_PAGES.COMPONENTS.DISCUSSION.ADD_DISCUSSION() : t.LECTURE_PAGES.COMPONENTS.DISCUSSION.EDIT_DISCUSSION()),
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/discussion-modal.html',
            controllerFunction: DiscussionFormModalController,
            formName: 'discussionForm',
          },
        },
        showCreateConfirmation: (lc, currentCourse, mode) => {
          if (lc?.teamDiscussion?.teamSet?.id && !currentCourse.courseLongTeamSet && mode === 'new') {
            return 'lecture_pages/templates/components/team-discussion-creation-confirmation-overlay.html';
          }
          return null;
        },
        afterSave: (dispatch, mode, lc, extraDeps) => {
          const { angularServices } = extraDeps;
          if (
            lc.teamDiscussion?.teamSet?.id
            && mode === 'new'
            && !angularServices.CurrentCourseManager.course.hasCourseLongTeamSet
          ) {
            angularServices.$state.reload();

            // Clearing the saving overlay text after creating the team formation component.
            dispatch(unsetSavingOverlayText());
          }
        },
      },
      wideEditDropdown: true,
    },
    [ComponentType.VIDEO]: {
      description: t.LECTURE_PAGES.COMPONENTS.VIDEO_LIST.DESCRIPTION,
      descriptionLowercase: t.LECTURE_PAGES.COMPONENTS.VIDEO_LIST.DESCRIPTION_LOWERCASE,
      pendoTagName: config.pendo.lectureEdit.video,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.video,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: t.LECTURE_VIDEO.ADD_VIDEO,
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/video-list-edit-modal.html',
            controllerFunction: VideoListEditFormController,
            formName: 'editVideoTitleForm',
          },
        },
      },
    },
    [ComponentType.VIDEO_PRACTICE]: {
      description: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.DESCRIPTION,
      pendoTagName: config.pendo.lectureEdit.videoPractice,
      lessonLibraryPendoTagName: config.pendo.lectureEdit.lessonLibrary.videoPractice,
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.CHOOSE_VIDEO_PRACTICE_OPTION,
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/video-practice-modal.html',
            controllerFunction: VideoPracticeEditFormModalController,
          },
        },
      },
    },
    [ComponentType.VIDEO_PRACTICE_FEEDBACK]: {
      description: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.DESCRIPTION,
      pendoTagName: '',
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.TITLE,
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/video-practice-evaluation-modal.html',
            controllerFunction: VideoPracticeEvaluationCtrl,
          },
        },
      },
      disabledTooltip: () => t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.UNAVAILABLE(),
      deleteWarning: () => t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.DELETE_CONFIRMATION.DESCRIPTION(),
    },
    [ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK]: {
      description: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.PRACTICE_SKILLS_FEEDBACK.DESCRIPTION,
      pendoTagName: '',
      workflow: {
        type: 'modal',
        renderModal: (props) => (<div>{props.children}</div>),
        initialSettings: {
          title: t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.MODAL.TITLE,
          angularConfig: {
            templateUrl: 'lecture_pages/templates/components/video-practice-evaluation-modal.html',
            controllerFunction: VideoPracticeEvaluationCtrl,
          },
        },
      },
      disabledTooltip: () => t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.UNAVAILABLE(),
      deleteWarning: () => t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.DELETE.DESCRIPTION(),
    },
  };

  deepFreeze(metadata);

  return cloneDeep(metadata[componentType]);
};

export const copyableLectureComponents: ComponentTrueType[] = [
  ComponentType.LIVE_SESSION,
  ComponentType.ACCORDION,
  ComponentType.ATTACHMENT,
  ComponentType.AUDIO,
  ComponentType.TOPIC,
  ComponentType.EXERCISE,
  ExternalToolType.LTI,
  ExternalToolType.SCORM,
  ExternalToolType.WEB_EMBED,
  ExternalToolType.WEB_LINK,
  ComponentType.IMAGE,
  ComponentType.PRIVATE_PEER_EVALUATION,
  ComponentType.PUBLIC_PEER_EVALUATION,
  ComponentType.QUIZ,
  RichTextType.FULL,
  ComponentType.SUBMISSION_DISCOVERY,
  RichTextType.SIMPLE,
  ComponentType.VIDEO,
  ComponentType.LINE_DIVIDER,
  StyledLinkType.BUTTON,
  StyledLinkType.CARD,
];

export default getComponentMetadata;


