import t from 'react-translate';
import { InsightCriterion, VideoPracticeScenario } from 'redux/schemas/models/video-practice';

type KeyPhrasesListProps = {
  title: string;
  phrases: string[];
};

const KeyPhrasesList = ({ title, phrases = [] }: KeyPhrasesListProps) => (
  phrases.length > 0 ? (
    <div>
      <span className='bold'>{title} </span>
      {phrases.map((keyphrase, index) => (
        <span key={keyphrase}>
          {keyphrase}{index < phrases.length - 1 ? ', ' : '.'}
        </span>
      ))}
    </div>
  ) : null
);


type InsightsDetailsEnabledProps = {
  insightCriterion: InsightCriterion;
};

const InsightsDetailsEnabled = ({ insightCriterion }: InsightsDetailsEnabledProps) => (
  <>
    <span>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.DETAILS.ENABLED()}</span>
    <KeyPhrasesList
      key='keyPhrasesToCover'
      title={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.DETAILS.PHRASES_TO_MENTION()}
      phrases={insightCriterion.keyphrasesToCover}
    />
    <KeyPhrasesList
      key='keyPhrasesToAvoid'
      title={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.DETAILS.PHRASES_TO_AVOID()}
      phrases={insightCriterion.keyphrasesToAvoid}
    />
  </>
);

type InsightsDetailsProps = {
  scenario: VideoPracticeScenario;
};

const InsightsDetails = ({ scenario }: InsightsDetailsProps) => (
  <div className='my-2 d-flex'>
    <i className='icon icon-data warning d-inline mr-2' />
    <div>
      <div className='d-inline'>
        {scenario.insightCriterion ? (
          <InsightsDetailsEnabled insightCriterion={scenario.insightCriterion} />
        ) : (
          <span>
            {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.INSIGHTS.DETAILS.DISABLED()}
          </span>
        )}
      </div>
    </div>
  </div>
);

export default InsightsDetails;
