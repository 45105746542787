import { css } from '@emotion/react';
import NvFroala from 'froala/components/nv-froala';
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import t from 'react-translate';
import moment from 'moment';

import { isSafari, hasGetUserMedia, hasMediaRecorder } from 'recording/services/media-stream';

import LectureComponentProvider, { useLectureComponentContext } from 'components/lecture-component-provider';
import { RootState } from 'redux/schemas';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import NvIcon from 'shared/components/nv-icon';
import ActivityPoints from 'shared/components/activity/activity-points';
import ActivityStatus from 'shared/components/activity/activity-status';
import ActivityTitle from 'shared/components/activity/activity-title';

import { RolesService, PermissionTypes } from 'institutions/services/roles-service';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray7, warning, primary } from 'styles/global_defaults/colors';
import { AngularServicesContext } from 'react-app';
import LectureComponentDeadline from 'components/lecture-component-deadline';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';

import {
  VideoPracticeActivity,
  VideoPracticeOption,
  VideoPracticeScenario,
  VideoPracticeSubmission,
} from 'redux/schemas/models/video-practice';
import { Enrollment } from 'redux/schemas/models/my-account';
import { getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getLecturePageIdsOfFeedbackActivities } from 'redux/selectors/video-practice-feedback';
import { unsetLecturePageAsCached } from 'redux/actions/lecture-pages';
import { useAppDispatch } from 'redux/store';
import { getFlyoutScenarios } from 'redux/actions/video-practice';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { isEmpty, omit } from 'underscore';
import usePreventLecturePageNavigation from 'lecture_pages/hooks/use-prevent-lecture-page-navigation';
import { TextAlign } from 'shared/components/nv-tooltip';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import PracticeActivityModal from './practice-activity-modal';
import VideoPracticeGallery from './video-practice-gallery';
import PracticeInstantInsightsModal from './practice-instant-insights-modal';
import { LectureComponentProps, LecturePageMode } from '..';
import {
  useGetVideoPracticeLectureComponent,
  useUpdatePracticeActivity,
  useUpdatePracticeScenario,
  getTimeLimits,
  getTotalTime,
  roundToMinutes,
} from './video-practice-utils';

import { config } from '../../../../config/pendo.config.json';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';

const VideoPracticeComponentContextWrapper = (props: LectureComponentProps<ComponentType.VIDEO_PRACTICE>) => {
  const isEdit = props.mode === LecturePageMode.EDIT;
  const isDeadlineEditable = props.mode === LecturePageMode.EDIT || props.mode === LecturePageMode.LINKED_EDIT;
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  return (
    <LectureComponentProvider
      catalogId={catalogId}
      lectureComponentId={props.lectureComponent.id}
      lecturePageId={props.currentLecture.id}
      isEdit={isEdit}
      angularComponent={props.angularComponent}
      isDeadlineEditable={isDeadlineEditable}
    >
      <VideoPracticeLectureComponent {...props} />
    </LectureComponentProvider>
  );
};

const VideoPracticeLectureComponent = ({
  currentLecture, mode,
}: LectureComponentProps<ComponentType.VIDEO_PRACTICE>) => {
  const styles = css`
    .not-supported {
      margin-top: ${standardSpacing}px;
      padding: ${standardSpacing}px;
      background-color: ${gray7};
      border-top: 1px solid ${warning};

      a {
        color: ${primary};
      }
    }
    .practice-text{
      a {
        color: ${primary};
      }
    }
  `;

  const dispatch = useAppDispatch();

  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);
  const { $uibModal, $scope, TimelinesManager, InstitutionsManager } = useContext(AngularServicesContext);

  const { isEdit, isDeadlineEditable, catalogId, lectureComponentId } = useLectureComponentContext();
  const lectureComponent = useGetVideoPracticeLectureComponent();
  const { practiceActivity: practiceActivityId } = lectureComponent;
  const activity = useSelector<RootState, VideoPracticeActivity>((state) => state.models.practiceActivities[practiceActivityId]);
  const scenario = useSelector<RootState, VideoPracticeScenario>((state) => state.models.practiceScenarios[activity.scenarioId]);
  const mySubmission = useSelector<RootState, VideoPracticeSubmission>((state) => state.models.practiceSubmissions[activity.submissionId]);
  const currentCourse = useSelector((state) => getCurrentCourse(state));
  const enrollmentForScenario = useSelector<RootState, Enrollment>((state) => Object.values(state.models.enrollments).find((enrl: any) => state.models.courses[enrl.courseCatalogId]?.catalogId === scenario.createdInCourse?.catalogId));
  const { isInstitutionalAdmin, speechToTextEnabled } = useSelector(getCurrentInstitution);
  const lecturePageIdsOfFeedbackActivities = useSelector((state: RootState) => getLecturePageIdsOfFeedbackActivities(state, practiceActivityId));

  const skillTags = useSelector((state: RootState) => getSkillTagsFromTaggings(state, scenario.skillTaggingIds)) || [];

  const { timeToView, timeToRecord, timeToReview } = getTimeLimits(scenario);
  const timeToViewRounded = roundToMinutes(timeToView);
  const timeToRecordRounded = roundToMinutes(timeToRecord);
  const timeToReviewRounded = roundToMinutes(timeToReview);
  const totalTimeRounded = getTotalTime(timeToViewRounded, timeToRecordRounded, timeToReviewRounded);

  const activityUpdater = useUpdatePracticeActivity();
  const scenarioUpdater = useUpdatePracticeScenario();

  const [showModal, setShowModal] = useState(false);
  const [loadingInsightModal, setLoadingInsightModal] = useState<boolean>(false);
  const [submissionId, setSubmissionId] = useState<number>();
  const [expectations, setExpectations] = useState(activity.expectations);
  const [activityTitle, setActivityTitle] = useState(activity.title);

  const deregisterStateChangeStartRef = useRef(null);

  const timelineService = useTimelineService();

  useEffect(() => {
    const extraOptions = [];

    const scenarioCanBeEdited = scenario.createdInCourse // The parent course is not deleted and is available
      // Check for roles
      && (isInstitutionalAdmin // Is org admin
        || enrollmentForScenario?.roles.admin // Is course admin
        || RolesService.hasPermission(enrollmentForScenario?.roles.permission, PermissionTypes.COURSE_BUILDER) // Is course builder
      );

    if (!currentCourse.isSelfPaced) {
      extraOptions.push(
        {
          type: 'text',
          text: activity.deadline ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
          disabled: currentCourse.isContentManagementCollection,
          tooltip: {
            enabled: currentCourse.isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            textAlign: TextAlign.LEFT,
            offset: 20,
          },
          callback: () => {
            activityUpdater({
              deadline: activity.deadline
                ? null
                : moment(currentLecture.releaseDate).add(1, 'weeks').endOf('day').toISOString(),
            });
          },
        },
        { type: 'divider' },
      );
    }
    extraOptions.push({
      type: 'text',
      text: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.BASICS(),
      callback: () => openEditModal({ editBasics: true, editScenario: false }),
      disabled: !!currentLecture.isLinked,
      tooltip: {
        text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
        textAlign: TextAlign.LEFT,
        enabled: !!currentLecture.isLinked,
        placement: 'left',
        offset: 20,
      },
    });
    if (isInstitutionalAdmin || enrollmentForScenario?.roles.admin || RolesService.hasPermission(enrollmentForScenario?.roles.permission, PermissionTypes.COURSE_BUILDER)) {
      extraOptions.push({
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.EDIT.PRACTICE_SCENARIO(),
        callback: () => openEditModal({ editBasics: false, editScenario: true }),
        disabled: !(!currentLecture.isLinked || scenario.reusable === 'not_reusable'),
        tooltip: {
          text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
          textAlign: TextAlign.LEFT,
          enabled: !(!currentLecture.isLinked || scenario.reusable === 'not_reusable'),
          placement: 'left',
          offset: 20,
        },
      });
    }

    setSharedProps({
      ...sharedProps,
      deleteConfirmationMessage: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.DELETE_MESSAGE(),
      extraOptions: {
        mode: 'prepend',
        options: extraOptions,
      },
    });
  }, [activity, scenario]);

  useEffect(() => {
    setActivityTitle(activity.title);
  }, [activity.title]);

  const checkPreventNavigation = () => expectations !== activity.expectations;

  const checkPreventNavigationRef = React.useRef<Function>();
  checkPreventNavigationRef.current = checkPreventNavigation;

  useEffect(() => {
    deregisterStateChangeStartRef.current = $scope.StateManager.registerStateChangeStart(
      checkPreventNavigationRef.current,
      'shared/templates/modal-navigate-away.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
    );

    const { current: deregister } = deregisterStateChangeStartRef;

    return () => {
      deregister();
    };
  }, [$scope.StateManager]);

  usePreventLecturePageNavigation(checkPreventNavigation);

  const openEditModal = ({ editBasics, editScenario }) => {
    const mins = Math.floor(scenario.recordingLimit / 60);
    const secs = scenario.recordingLimit % 60;

    const modalInstance = $uibModal.open({
      templateUrl: 'lecture_pages/templates/components/video-practice-modal.html',
      controller: 'VideoPracticeEditFormModalCtrl',
      controllerAs: 'vm',
      windowClass: 'discussions-modal video-practice-modal',
      resolve: {
        vmResolves: {
          formData: {
            videoPracticeOption: activity.videoPracticeOption,
            maxTries: activity.maxTries,
            title: scenario.title,
            textPrompt: scenario.textPrompt,
            videoPrompt: scenario.videoPrompt,
            recordingLimit: {
              min: {
                type: 'text',
                id: mins,
                value: mins,
                text: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.MINS_TEXT(mins),
              },
              sec: {
                type: 'text',
                id: secs / 15,
                value: secs,
                text: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.RECORDING_LIMIT.SECS_TEXT(secs),
              },
            },
            recordingFormat: scenario.recordingFormat,
            privacy: scenario.privacy,
            reusable: scenario.reusable,
            createdAt: scenario.createdAt,
            createdInCourse: scenario.createdInCourse,
            createdInCollection: scenario.createdInCollection,
            numCoursesUsedIn: scenario.numCoursesUsedIn,
            coursesUsedIn: scenario.coursesUsedIn,
            numCollectionsUsedIn: scenario.numCollectionsUsedIn,
            collectionsUsedIn: scenario.collectionsUsedIn,
            scenarioId: scenario.id,
            insightCriterion: { ...omit(scenario?.insightCriterion, 'id') },
            isInsightsEnabled: !isEmpty(scenario?.insightCriterion),
          },
          editBasics,
          editScenario,
          lectureComponent,
        },
      },
    });

    modalInstance.result.then((res) => {
      if (editBasics) {
        activityUpdater(res);
      } else {
        scenarioUpdater(res);
      }
    });
  };

  const onStart = () => {
    setShowModal(true);
  };

  const afterSubmit = useCallback((submission: VideoPracticeSubmission) => {
    const {
      pointsReceived,
      leaderboardPoints,
      leaderboardRank,
      priorLeaderboardRank,
    } = submission;

    setSubmissionId(submission?.id);
    const wasPracticeRoomEnabled = $scope.MainGridCtrl.practiceRoomEnabled;
    if (!wasPracticeRoomEnabled) {
      $scope.MainGridCtrl.practiceRoomEnabled = true;
    }
    setShowModal(false);

    if (speechToTextEnabled && scenario.insightCriterion) {
      const pointsDelay = pointsReceived ? 3000 : 0;
      const skillTagsDelay = skillTags.length > 0 ? 3000 : 0;
      const delay = pointsDelay + skillTagsDelay;
      setTimeout(() => {
        setLoadingInsightModal(true);
      }, delay + 500);
    }
    dispatch(getFlyoutScenarios({ page: 1 }))
      .catch((error) => {
        // In case the endpoint fails, we set back the original value of practiceRoomEnabled
        $scope.MainGridCtrl.practiceRoomEnabled = wasPracticeRoomEnabled;
      });

    lecturePageIdsOfFeedbackActivities.forEach((lecturePageId) => {
      dispatch(unsetLecturePageAsCached(lecturePageId));
    });

    if (pointsReceived || skillTags.length > 0) {
      $uibModal.open({
        templateUrl: 'shared/templates/points-modal.html',
        windowClass: 'points-modal',
        controller: 'PointsModalCtrl as vm',
        resolve: {
          pointsReceived,
          leaderboardPoints,
          leaderboardRank,
          priorLeaderboardRank,
          extras: { skillTags },
        },
      });

      if (pointsReceived) {
        // Update points and progress of timeline after activity has been submitted
        timelineService.updateTimeline(lectureComponent.lecturePageId);
        TimelinesManager.updateComponentPointsAndProgress(
          lectureComponent.lecturePageId,
          lectureComponent.type,
          lectureComponent.id,
          pointsReceived,
        );
      }
    } else {
      timelineService.updateTimeline(lectureComponent.lecturePageId);
      TimelinesManager.updateComponentProgress(
        lectureComponent.lecturePageId,
        lectureComponent.type,
        lectureComponent.id,
        'completed',
      );
    }
  }, [$scope.MainGridCtrl, $uibModal, TimelinesManager, dispatch, lectureComponent, lecturePageIdsOfFeedbackActivities, speechToTextEnabled, scenario.insightCriterion]);

  const afterLeave = useCallback(() => { }, []);

  let practiceDisabled = false;
  const activityNotReleased = moment().isBefore(moment(activity.releaseDate));

  if (
    isEdit
    || activityNotReleased
    || currentCourse.isContentManagementCollection
    || (mode === LecturePageMode.LINKED_EDIT && currentLecture.isLinked)
  ) {
    practiceDisabled = true;
  } else if (activity.videoPracticeOption === VideoPracticeOption.ON_SPOT && activity.progress && activity.progress !== 'not_started') {
    practiceDisabled = true;
  } else if (activity.videoPracticeOption === VideoPracticeOption.WHEN_READY && activity.progress && (activity.progress !== 'not_started' && activity.progress !== 'in_progress')) {
    // User can continue to practice in "when_ready" type as long as they haven't missed the deadline
    practiceDisabled = true;
  }


  const browserNotSupported = isSafari || !hasGetUserMedia() || !hasMediaRecorder();

  let bottomContent;
  if (!isEdit && browserNotSupported) {
    bottomContent = (
      <div className='not-supported'>
        <NvIcon icon='ban' size='small' className='text-gray-3 mb-4 text-center' />
        {t.FILE_UPLOAD.CANNOT_RECORD.BROWSER_SUPPORT()}
      </div>
    );
  } else if (activity.progress !== 'completed') {
    let details;

    if (activity.videoPracticeOption === VideoPracticeOption.ON_SPOT) {
      details = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.TOTAL_TIME_TO_COMPLETE(totalTimeRounded);
    }

    if (activity.progress === 'missed') {
      if (activity.deadlinePassed) {
        details = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.MISSED.DEADLINE();
      }

      // if deadline missed, but user attempted activity, should display different text
      if ((!activity.deadlinePassed && !mySubmission) || (mySubmission && !mySubmission.submitted)) {
        details = t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.MISSED.LEFT_ACTIVITY();
      }
    }

    bottomContent = (
      <React.Fragment>
        {details && <div className='text-center text-small text-gray-3 mt-5'>{details}</div>}
        <div className='text-center mt-2'>
          <Button
            variant='primary'
            onClick={onStart}
            disabled={practiceDisabled}
            pendo-tag-name={config.pendo.practice.startPractice}
            data-qa={`${config.pendo.practiceActivity.learner.practiceNow}_${scenario.recordingFormat}`}
          >
            {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.PRACTICE_NOW()}
          </Button>
        </div>
      </React.Fragment>
    );
  }
  const isActivityExpectationValid = (text) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;
    if (tempElement.innerText.trim() === 'undefined') {
      return false;
    }

    return !!tempElement.innerText.trim();
  };

  return (
    <div id={`lecture-component-${lectureComponentId}`}>
      <div css={styles}>
        <div className='d-flex flex-column align-items-center mb-4'>
          <ActivityStatus icon='media-practice' status={activity.progress} />
          <ActivityTitle
            status={activity.progress}
            statusesTexts={{
              not_started: t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.PRACTICE(),
              completed: t.EXERCISES.SUBMITTED(),
            }}
          />
          <LectureComponentHeaderInput
            value={activityTitle}
            editable={isEdit}
            onBlur={() => activity.title !== activityTitle && activityUpdater({ title: activityTitle })}
            onChange={(newTitle) => setActivityTitle(newTitle)}
            required
          />
          {activity.pointsConfiguration && (
            <ActivityPoints
              pointsReceived={activity.pointsReceived}
              totalPoints={activity.pointsConfiguration.points}
            />
          )}
          {activity.deadline && (
            <div className='mt-2'>
              <LectureComponentDeadline
                componentName='video-practice-deadline'
                expirationDate={activity.deadline}
                lecturePageId={currentLecture.id}
                catalogId={catalogId}
                isEdit={isEdit}
                hasHardDeadlines={activity.hardDeadline}
                onHardDeadlineChange={(hardDeadline) => {
                  activityUpdater({ hardDeadline });
                }}
                onDeadlineChange={(date) => {
                  activityUpdater({ deadline: date?.toISOString() });
                }}
                isDeadlineEditable={isDeadlineEditable}
              />
            </div>
          )}
        </div>
        {isEdit ? (
          <NvFroala
            value={expectations}
            onChange={setExpectations}
            onBlur={() => {
              activityUpdater({ expectations });
            }}
            placeholder={t.LECTURE_PAGES.COMPONENTS.EXERCISE.ADD_EXPECTATIONS()}
          />
        ) : (
          isActivityExpectationValid(activity.expectations) && <div className='practice-text text-body' dangerouslySetInnerHTML={{ __html: activity.expectations }} />
        )}
        {bottomContent}
      </div>

      {showModal && (
        <PracticeActivityModal
          practiceType={activity.videoPracticeOption}
          activity={activity}
          scenario={scenario}
          setShowModal={setShowModal}
          afterSubmit={afterSubmit}
          afterLeave={afterLeave}
        />
      )}
      {loadingInsightModal && (
        <PracticeInstantInsightsModal
          submissionId={submissionId}
          scenarioId={scenario.id}
          setShowInsightsModal={setLoadingInsightModal}
        />
      )}

      <VideoPracticeGallery />
    </div>
  );
};

export default VideoPracticeComponentContextWrapper;
