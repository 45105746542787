/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import t from 'react-translate';
import { DeepPartial } from 'utility-types';
import { decamelize } from 'humps';
import moment from 'moment';
import { omit } from 'underscore';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CollectionLecturePage, CombinedLecturePage, Estimation, LecturePage, NLecturePage,
  PreventNavigationReason, UpdateLectureReleaseDateResponse, NovoAI, NovoAIContent,
  NovoAIItemType,
} from 'redux/schemas/models/lecture-page';
import { LectureComponent, NLectureComponent, AccordionSectionLectureComponent, ComponentTrueType } from 'redux/schemas/models/lecture-component';
import { Result } from 'redux/schemas/api';
import { LeftPanelView } from 'redux/schemas/app/lecture-page';
import { LecturePageUrlParams } from 'lecture_pages/components/lecture-page-routes';
import { RootState } from 'redux/schemas';
import { CourseExercise } from 'redux/schemas/models/exercise';
import { FetchCourseParams } from 'redux/schemas/api/course';
import { getLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { LecturePagLinksParams, LecturePageUnLinkParams } from 'redux/schemas/api/lecture-pages';
import { LecturePageMode } from 'lecture_pages/components';
import { getCourseLecturePageTimeline } from './timeline';
import { addAlertMessage } from './alert-messages';

interface LectureComponentSaveParams {
  catalogId: string,
  lecturePageId: number,
  lectureComponent: NLectureComponent,
}

type LecturePageActionParams = LecturePageUrlParams & {
  currentCourseIsCollection?: boolean
  isFromLessonsList?: boolean
};

export interface LectureComponentUpdateParams<C extends ComponentTrueType = any> {
  catalogId: string,
  /** Note that this is a number where usually this prop is a string in other structs. We receive it as a number on the
   * lecture component object from the backend but usually convert it to a string when used with URLs */
  lecturePageId: number,
  /**
   * Note that API doesn't always expect all the possible properties that
   * the following type can have.
   */
  componentData: DeepPartial<NLectureComponent<C>> & {
    /** 'id' is required as it's used to construct the request URL */
    id: NLectureComponent['id'],
    /** 'type' is required as it's used to construct the request URL */
    type: NLectureComponent['type']
  },
  /** Whether to show the full page overlay showing the update is being saved */
  showSavingOverlay?: boolean,
  commitChanges?: boolean,
}

const lectureAPIUrl = <T extends LecturePageActionParams>(params: T) => (
  `/${params?.currentCourseIsCollection ? 'content_management' : params.catalogId}/lecture_pages/${params.lecturePageId}`);

async function fireSaveLectureComponent(params: LectureComponentSaveParams): Promise<NLectureComponent> {
  const response = await axios.post(`${lectureAPIUrl(params)}/${params.lectureComponent.type}`);
  return response.data.result;
}

async function fireCreateLecturePage(params: { catalogId: string, previousLectureId: number }) {
  return axios.post<Result<LecturePage>>(`${params.catalogId}/lecture_pages.json`, {
    lecturePage: {
      lecturePageIdToCreateUnder: params.previousLectureId,
      releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
    },
  }).then(response => response.data.result);
}

async function fireDuplicateLecturePage(params: LecturePageActionParams) {
  return axios.post<Result<CombinedLecturePage>>(`${lectureAPIUrl(params)}/duplicate.json`, {
    lecturePage: {
      releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
    },
  }).then(response => response.data.result);
}

async function fireDeleteLecturePage(params: LecturePageActionParams) {
  return axios.delete<Result<boolean>>(`${lectureAPIUrl(params)}.json`)
    .then(response => response.data.result);
}

/** Unlike `loadLecturePage`, actually performs an API request to load lecture page data */
export const getLecturePage = createAsyncThunk('GET_LECTURE_PAGE',
  async (params: LecturePageUrlParams, thunkAPI) => {
    const appState = (thunkAPI.getState() as RootState).app;
    const isAdminMode = appState.destinationLecturePage?.id === params.lecturePageId
      ? false : (thunkAPI.getState() as RootState).app.lecturePage.isAdminMode;

    const response = await axios.get<Result<LecturePage>>(`${lectureAPIUrl(params)}.json?edit_mode=${isAdminMode.toString()}`);
    return response.data.result;
  });

export const createLecturePage = createAsyncThunk('CREATE_LECTURE_PAGE', fireCreateLecturePage);
export const duplicateLecturePage = createAsyncThunk('DUPLICATE_LECTURE_PAGE', fireDuplicateLecturePage);
export const deleteLecturePage = createAsyncThunk('DELETE_LECTURE_PAGE', fireDeleteLecturePage);

export const linkCollectionLessonsToCourse = createAsyncThunk(
  'LINK_COLLECTION_LESSONS_TO_COURSE',
  async (params: LecturePagLinksParams, { dispatch, rejectWithValue }) => {
    const payload = {
      link: omit(params, 'courseId'),
    };

    return axios.post(`content_management/courses/${params.courseId}/link.json`, payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);

export const convertToCollectionLesson = createAsyncThunk(
  'CONVERT_TO_COLLECTION_LESSON',
  async (params: { lecturePageId: number, folderId: number }, { dispatch, rejectWithValue }) => {
    const payload = {
      lecturePage: {
        folderId: params.folderId,
      },
    };

    return axios.post(`content_management/lecture_pages/${params.lecturePageId}/convert.json`, payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  },
);

export const unlinkCollectionLesson = createAsyncThunk('UNLINK_COLLECTION_LESSON',
  async (params: LecturePageUnLinkParams, { dispatch, rejectWithValue }) => {
    const payload = {
      unlink: omit(params, 'courseId'),
    };

    return axios.post(`/content_management/courses/${params.courseId}/unlink.json`, payload).then(
      (response) => response.data.result,
    ).catch((e) => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.ERROR_SOMETHING_WRONG(),
      }));

      return rejectWithValue(e.response.data.error);
    });
  });

/** Updates the properties of a lecture page object, used to set new display & info values for background color, title text,
 * time estimate, release date, etc. */
export const updateLecturePage = createAsyncThunk('UPDATE_LECTURE_PAGE',
  async (params: {
    lecturePage: Partial<NLecturePage | CollectionLecturePage>,
    currentCourseIsCollection?: boolean,
  } & LecturePageUrlParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const storeLP = (thunkAPI.getState() as RootState).models.lecturePages[params.lecturePageId ?? getLecturePageParams(state)?.lecturePageId];

    const response = await axios.put<Result<LecturePage | CollectionLecturePage>>(
      `${lectureAPIUrl(params)}.json`,
      {
        // For unknown reasons this API seems to require us to send all of these properties
        // in every call. Otherwise, it will fail with a 500
        lecturePage: {
          title: storeLP.title,
          viewOptions: storeLP.viewOptions,
          showTitle: storeLP.showTitle,
          ...params.lecturePage,
        },
      },
    );

    return response.data.result;
  });

// Used only to update the estimate values on a lecture page. I don't see any reason why this API shouldn't
// be built into the updateLecturePage() API above
export const updateLecturePageEstimates = createAsyncThunk('UPDATE_LECTURE_PAGE_ESTIMATES',
  async (params: {
    estimate: Estimation,
  } & LecturePageUrlParams) => axios.post<Result<LecturePage>>(
    `${lectureAPIUrl(params)}/update_estimates.json`,
    {
      estimates: params.estimate,
    },
  ).then(response => response.data.result));

/**
 * This API is specifically used to update the Release Date value on a lecture page. When using
 * the lecture page update API for this purpose, we encounter issues with linked lessons. This
 * occurs because the whole lecture page parameter breaks the link. Therefore, we are using
 * the Release Date updating API, as used in the outline.
 */
export const updateLecturePageReleaseDate = createAsyncThunk('UPDATE_LECTURE_PAGE_RELEASE_DATE',
  async (params: Pick<LecturePage, 'releaseDate' | 'released'> & LecturePageUrlParams) => axios.put<Result<UpdateLectureReleaseDateResponse>>(
    `/${params.catalogId}/lecture_pages/${params.lecturePageId}/update_release_date.json`,
    {
      releaseDate: params.releaseDate,
    },
  ).then(response => response.data.result.updatedObject));

// Sets lecture page view options locally without saving to provide an immediately viewable preview of their appearance
export const previewLecturePageViewOptions = createAction<{
  lecturePageId: number,
  viewOptions: Partial<LecturePage['viewOptions']>,
}>('PREVIEW_LECTURE_PAGE_HEADER');


// TODO: I think all actions below this line should be moved to different files

// TODO: This is currently not used anywhere. We probably want to combine this with updateLectureComponent() below
export const saveLectureComponent = createAsyncThunk(
  'SAVE_LECTURE_COMPONENT',
  fireSaveLectureComponent,
);

let updateLectureComponentCancel;
export const updateLectureComponent = createAsyncThunk(
  'UPDATE_LECTURE_COMPONENT',
  async (params: LectureComponentUpdateParams, thunkAPI): Promise<LectureComponent> => {
    if (updateLectureComponentCancel) {
      updateLectureComponentCancel.cancel();
    }
    updateLectureComponentCancel = axios.CancelToken.source();

    const data = {
      lectureComponent: {
        ...params.componentData,
        onlyDownstreamOutlineUpdate: false,
      },
    };

    const response = await axios.put<Result<LectureComponent>>(
      `${lectureAPIUrl(params)}/${decamelize(params.componentData.type, { separator: '_' })}/${params.componentData.id}`,
      data,
      { cancelToken: updateLectureComponentCancel.token },
    );

    // Request an update to the timeline data for the current lecture page
    // (actually requests a serialized lecture page object, but is seemingly only needed to update lecture page status)
    thunkAPI.dispatch(getCourseLecturePageTimeline({
      ...params,
      // TODO: Is this a safe/performant way to get the current user ID?
      userId: (thunkAPI.getState() as RootState).app.currentUserId,
    }));

    return response.data.result;
  },
);

export const moveComponent = createAction<{
  lecturePageId: number,
  componentIds: number[],
}>('MOVE_LECTURE_COMPONENT');

// TODO: Remove/rework. This is a legacy action that should be replaced by `setLhsVisibility` below
export const setTimelineVisible = createAction<boolean>('SET_TIMELINE_VISIBLE');

export const setLeftPanelVisibility = createAction<boolean>('SET_LEFT_PANEL_VISIBILITY');
export const setBottomBarVisibility = createAction<boolean>('SET_BOTTOM_BAR_VISIBILITY');
export const setTopBarVisibility = createAction<boolean>('SET_TOP_BAR_VISIBILITY');
export const setLeftPanelView = createAction<LeftPanelView>('SET_LEFT_PANEL_VIEW');
export const setNewComponentIndex = createAction<number>('SET_NEW_COMPONENT_INDEX');
export const setLectureHeaderIsSaving = createAction<boolean>('SET_LECTURE_HEADER_IS_SAVING');
export const setLecturePageModeIsSwitching = createAction<boolean>('SET_LECTURE_PAGE_MODE_IS_SWITCHING');

export const setLectureAppStateFromRouteParams = createAction<{ isAdminMode: boolean } & LecturePageUrlParams>('SET_LECTURE_APP_STATE_FROM_ROUTE_PARAMS');

export const markLecturePageAsViewed = createAsyncThunk('MARK_LECTURE_PAGE_VIEWED',
  async (params: Omit<LecturePageUrlParams, 'collectionId'>) => (await axios.post<Result<boolean>>(`${lectureAPIUrl(params)}/view.json`)).data);

export const markLecturePageExited = createAction<number>('MARK_LECTURE_PAGE_EXITED');

/** Sets a lecture page as being loaded so that it's served from the "cache". See
 * documentation for state.app.lecturePage.cachedLecturePageLookup */
export const setLecturePageAsCached = createAction<number>('SET_LECTURE_PAGE_AS_CACHED');

export const unsetLecturePageAsCached = createAction<number>('UNSET_LECTURE_PAGE_AS_CACHED');

// When exiting from lecture page experience unset the caching of all lecture pages
export const unsetAllCachedLecturePages = createAction('UNSET_ALL_CACHED_LECTURE_PAGES');

// When exiting from lecture page experience unset the the lecture page states from app
export const unsetcurrentLectureAppState = createAction('UNSET_CURRENT_LECTURE_APP_STATE');

export const lecturePageSyncingStatusPusherUpdate = createAction<{
  lecturePageId: number,
  hasUpdatedLinks: boolean,
}>('LECTURE_PAGE_SYNCING_STATUS_PUSHER_UPDATE');

/** Saves the user's preference on whether the Lecture Page left hand panel is collapsed/open upon page load */
export const setTimelineIsExpanded = createAsyncThunk(
  'SET_TIMELINE_IS_EXPANDED',
  async (isExpanded: boolean) => axios.post<Result<boolean>>('/users/update_is_timeline_expanded.json', {
    isTimelineExpanded: isExpanded,
  }).then(response => response.data.result),
);

export const getCourseExercises = createAsyncThunk(
  'GET_COURSE_EXERCISES',
  async (params: FetchCourseParams) => (await axios.get<Result<CourseExercise[]>>(`${params.catalogId}/exercises.json`)).data,
);


// TODO: We shouldn't be putting component-specific actions here; these should be refactored into an actions file for that specific component

//
// Accordion actions
//
type AccordionSectionAction = 'ADD' | 'UPDATE';
export type NewAccordionSection = Pick<AccordionSectionLectureComponent, 'viewOptions' | 'index' | 'content' | 'header' | 'picture'>;
interface AccordionSectionBaseParams<Action extends AccordionSectionAction> {
  catalogId: string;
  lecturePageId: number;
  accordionId: number;
  childLectureComponent: Action extends 'ADD' ? NewAccordionSection : AccordionSectionLectureComponent;
}

// same params as AccordionSectionAction<'ADD'> except for childLectureComponent
// since we only use the childLectureComponentId
interface DeleteAccordionSectionParams extends Omit<AccordionSectionBaseParams<'ADD'>, 'childLectureComponent'> {
  childLectureComponentId: AccordionSectionLectureComponent['id'];
}


export const addAccordionSection = createAsyncThunk(
  'ADD_ACCORDION_SECTION',
  async (params: AccordionSectionBaseParams<'ADD'>) => {
    const data = {
      childLectureComponent: params.childLectureComponent,
    };
    const response = await axios.post(`/${params.catalogId}/lecture_pages/${params.lecturePageId}/accordion_lecture_component/${params.accordionId}/accordion_section`, data);
    return response.data.result as AccordionSectionLectureComponent;
  },
);

export const updateAccordionSection = createAsyncThunk(
  'UPDATE_ACCORDION_SECTION',
  async (params: AccordionSectionBaseParams<'UPDATE'>, { signal }) => {
    const source = axios.CancelToken.source();
    // listen to abort events triggered from some component and translate it to
    // a axios cancel token
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const data = {
      childLectureComponent: params.childLectureComponent,
    };
    const response = await axios.put(`/${params.catalogId}/lecture_pages/${params.lecturePageId}/accordion_lecture_component/${params.accordionId}/accordion_section/${params.childLectureComponent.id}`, data, { cancelToken: source.token });
    return response.data.result as AccordionSectionLectureComponent;
  },
);

export const deleteAccordionSection = createAsyncThunk(
  'DELETE_ACCORDION_SECTION',
  async (params: DeleteAccordionSectionParams) => {
    const response = await axios.delete(`/${params.catalogId}/lecture_pages/${params.lecturePageId}/accordion_lecture_component/${params.accordionId}/accordion_section/${params.childLectureComponentId}`);
    return response.data.result as AccordionSectionLectureComponent;
  },
);

export const setPreventLecturePageNavigation = createAction<PreventNavigationReason>('SET_PREVENT_LECTURE_PAGE_NAVIGATION');

export const checkScormStatus = createAsyncThunk(
  'CHECK_SCORM_STATUS',
  async (params: {
    catalogId: string,
    lecturePageId: number,
  }) => {
    const response = await axios.post<Result<boolean>>(`/${params.catalogId}/external_tool_submissions/check_status/${params.lecturePageId}.json`, {});
    return response.data.result;
  },
);

export const resetTimelineLoadedState = createAction('RESET_TIMELINE_LOADED_STATE');

export const setDestinationLecturePage = createAction<{ lecturePageId: number, mode: LecturePageMode }>('SET_DESTINATION_LECTURE_PAGE_PARAMS');

export const resetDestinationLecturePage = createAction('RESET_DESTINATION_LECTURE_PAGE');
export const lecturePageBottomReachedState = createAction<boolean>('LECTURE_PAGE_BOTTOM_REACHED_STATE');

// Novo AI start
export const setLeftPanelNovoAIItemType = createAction<NovoAIItemType>('SET_LEFT_PANEL_NOVO_AI_ITEM_TYPE');
export const setLeftPanelNovoAIContentId = createAction<NovoAIContent>('SET_LEFT_PANEL_NOVO_AI_CONTENT_ID');
export const resetLeftPanelNovoAI = createAction<NovoAIItemType>('RESET_LEFT_PANEL_NOVO_AI');
export const setLeftPanelAiMode = createAction<NovoAI>('SET_LEFT_PANEL_AI_MODE');
export const setLeftPanelNovoAIItemTrueType = createAction<ComponentTrueType>('SET_LEFT_PANEL_NOVO_AI_ITEM_TRUE_TYPE');
export const setLeftPanelNovoAILengthAndTone = createAction<{ lengthText: string, tone: string }>('SET_LEFT_PANEL_NOVO_AI_LENGTH');
export const setNovoAIProgressiveQuizQuestionModalId = createAction<number>('SET_NOVO_AI_PROGRESSIVE_QUIZ_QUESTION_MODAL_ID');
// Novo AI end
